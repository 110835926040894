import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Divider, InputLabel, Link, MenuItem, Select, Stack, TextField, Typography } from '@mui/material'
import { CAMPFIRE_TEMPLATE_COLLECTION, COMPANY_COLLECTION, TEAM_COLLECTION } from '../../_constants/globals'
import useFirestore from '../../hooks/useFirestore'
import VisuallyHiddenInput from '../_library/VisuallyHiddenInput'
import InfoBadge from '../_library/InfoBadge'


const WriteCampfireForm = ({ campfire, onChange }) => {
  
  const companyHooks = useFirestore(COMPANY_COLLECTION)
  const dbCompanies = companyHooks.getDocs()
  const teamHooks = useFirestore(TEAM_COLLECTION)
  const dbTeams = teamHooks.getDocs()
  const campfireTemplateHooks = useFirestore(CAMPFIRE_TEMPLATE_COLLECTION)
  const dbCampfireTemplates = campfireTemplateHooks.getDocs()
  
  const [client, setClient] = useState(null)
  const [team, setTeam] = useState(null)
  const [template, setTemplate] = useState(null)
  const [spreadsheet, setSpreadsheet] = useState(campfire?.spreadsheetUrl)
  const [debrief, setDebrief] = useState(campfire?.debriefUrl)
  const [comment, setComment] = useState(campfire?.comment)
  
  const filteredTeams = useMemo(() => client && dbTeams.filter(t => t.companyRef.id === client?.id), [client])
  
  useEffect(() => {
    campfireTemplateHooks.listen({
      orderBy: [['order', 'asc']],
    })
    return campfireTemplateHooks.unsubscribe
  }, [])
  
  useEffect(() => {
    if (campfire?.companyRef && dbCompanies) setClient(dbCompanies.find(t => t.id === campfire.companyRef.id))
  }, [campfire?.companyRef, dbCompanies])
  
  useEffect(() => {
    if (campfire?.teamRef && dbTeams) setTeam(dbTeams.find(t => t.id === campfire.teamRef.id))
  }, [campfire?.teamRef, dbTeams])
  
  useEffect(() => {
    if (campfire?.templateRef && dbCampfireTemplates) setTemplate(dbCampfireTemplates.find(t => t.id === campfire.templateRef.id))
  }, [campfire?.templateRef, dbCampfireTemplates])
  
  useEffect(() => {
    onChange({ client, team, spreadsheet, debrief, comment, template })
  }, [client, team, spreadsheet, debrief, comment, template])
  
  return <form>
    <Stack spacing={3}>
      <Typography variant='h2' color='primary'>Pour quel client ?</Typography>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <InputLabel sx={{ width: '80px', fontWeight: 'bold' }}>Client :</InputLabel>
        {dbCompanies && <Select
          id='select-client'
          size='small'
          value={client?.id || ''}
          onChange={e => setClient(dbCompanies.find(c => c.id === e.target.value))}
        >
          {dbCompanies.map(c => <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>)}
        </Select>}
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <InputLabel sx={{ width: '80px', fontWeight: 'bold' }}>Equipe :</InputLabel>
        {filteredTeams && <Select
          id='select-team'
          size='small'
          value={team?.id || ''}
          onChange={e => setTeam(dbTeams.find(c => c.id === e.target.value))}
        >
          {filteredTeams.map(t => <MenuItem key={t.id} value={t.id}>{t.name}</MenuItem>)}
        </Select>}
      </Box>
      <Divider />
      <Typography variant='h2' color='primary'>Téléchargez le modèle</Typography>
      <Link href='#' underline='hover' sx={{ color: 'tertiary.main', textAlign: 'end', fontWeight: 'bold' }}>
        voir le catalogue des Feux de Camps &gt;
      </Link>
      <Typography><b>D&apos;abord, téléchargez le modèle de Feux de Camp</b> que vous souhaitez :</Typography>
      {dbCampfireTemplates && <Select
        id='select-type'
        size='medium'
        value={template?.id || ''}
        onChange={e => setTemplate(dbCampfireTemplates.find(t => t.id === e.target.value))}
        sx={{ '& .MuiInputBase-input': { py: 1 } }}
      >
        {dbCampfireTemplates.map(t => <MenuItem key={t.id} value={t.id}>
          <Box component='img' src={t.cardUrl} alt={t.slug} sx={{ height: 50, verticalAlign: 'middle', mr: 2 }} />
          <Typography fontWeight='bold' display='inline'>{t.title.fr}</Typography>
        </MenuItem>)}
      </Select>}
      {template && <>
        <Link
          href={template.spreadsheetUrl}
          target='_blank'
          rel='noopener noreferrer'
          underline='always'
          sx={{ color: 'grey.500', cursor: 'pointer' }}
        >
          Télécharger le modèle de Feux de Camps
        </Link>
        <Link
          href={template.debriefUrl}
          target='_blank'
          rel='noopener noreferrer'
          underline='always'
          sx={{ color: 'grey.500', cursor: 'pointer' }}
        >
          Télécharger le modèle de Récit d&apos;équipe
        </Link>
        <Divider />
        <Typography variant='h2' color='primary'>Personnalisez le Feu de Camp</Typography>
        <Typography fontWeight='bold'>Ensuite, uploadez ici la version modifiée qui sera remise à votre client</Typography>
        {template && <Box sx={{ width: '100%', bgcolor: 'grey.150', p: 1, borderRadius: 1, border: 'solid 1px', borderColor: 'grey.400' }}>
          <Box component='img' src={template.cardUrl} alt={template.slug} sx={{ height: 50, verticalAlign: 'middle', mr: 2 }} />
          <Typography fontWeight='bold' display='inline'>{template.title.fr}</Typography>
        </Box>}
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <InfoBadge title='Votre client sera notifié par e-mail' display={campfire?.status === 'actif'}>
            <Link component='label' underline='always' sx={{ color: 'grey.500', cursor: 'pointer' }}>
              {spreadsheet ? 'modifier' : 'uploader'} le feux de camp
              <VisuallyHiddenInput type='file' onChange={e => setSpreadsheet(e.target.files[0])} />
            </Link>
          </InfoBadge>
          {spreadsheet && <Link
            href={spreadsheet instanceof File ? URL.createObjectURL(spreadsheet) : spreadsheet}
            target='_blank'
            rel='noopener noreferrer'
            underline='always'
            sx={{ color: 'grey.500', cursor: 'pointer' }}
          >
            voir votre version modifiée
          </Link>}
        </Box>
        <Divider sx={{ borderStyle: 'dashed', mt: 2 }} />
        <Typography fontWeight='bold'>Uploadez le débrief ici</Typography>
        {template && <Box sx={{ width: '100%', bgcolor: 'grey.150', p: 1, borderRadius: 1, border: 'solid 1px', borderColor: 'grey.400' }}>
          <Box component='img' src={template.cardUrl} alt={template.slug} sx={{ height: 50, verticalAlign: 'middle', mr: 2 }} />
          <Typography fontWeight='bold' display='inline'>{template.title.fr}</Typography>
        </Box>}
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <InfoBadge title='Votre client sera notifié par e-mail' display={campfire?.status === 'actif'}>
            <Link component='label' underline='always' sx={{ color: 'grey.500', cursor: 'pointer' }}>
              {debrief ? 'modifier' : 'uploader'} le Récit d&apos;équipe
              <VisuallyHiddenInput type='file' onChange={e => setDebrief(e.target.files[0])} />
            </Link>
          </InfoBadge>
          {debrief && <Link
            href={debrief instanceof File ? URL.createObjectURL(debrief) : debrief}
            target='_blank'
            rel='noopener noreferrer'
            underline='always'
            sx={{ color: 'grey.500', cursor: 'pointer' }}
          >
            voir votre version modifiée
          </Link>}
        </Box>
      </>}
      <Divider sx={{ borderStyle: 'dashed', mt: 2 }} />
      <Typography fontWeight='bold'>Quel message souhaitez vous écrire à votre client pour accompagner ce Feu de Camp ?</Typography>
      <TextField fullWidth multiline rows={8} value={comment} onChange={e => setComment(e.target.value)} />
    </Stack>
  </form>
}

WriteCampfireForm.propTypes = {
  campfire: PropTypes.object,
  onChange: PropTypes.func.isRequired,
}

export default WriteCampfireForm
