import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import dropPhoto from '../../assets/avatar_team_member.svg'
import miniloader from '../../assets/miniloader.gif'
import clsx from 'clsx'
import { makeStyles } from '@mui/styles'
import { Divider, Link } from '@mui/material'
import { USER_COLLECTION } from '../../_constants/globals'
import useAuth from '../../hooks/useAuth'
import useFirestore from '../../hooks/useFirestore'


const useStyles = makeStyles(theme => ({
  root: {
    display: 'inline-block',
  },
  image: {
    width: 50,
    verticalAlign: 'middle',
  },
  actionBtn: {
    padding: theme.spacing(1),
    color: theme.palette.grey[500],
    cursor: 'pointer',
  },
  divider: {
    display: 'inline',
    borderColor: theme.palette.grey[500],
  },
  inputFile: {
    width: '0.1px',
    height: '0.1px',
    opacity: 0,
    overflow: 'hidden',
    position: 'absolute',
    zIndex: '-1',
  },
}))

const AvatarInput = ({ className, user }) => {

  const classes = useStyles()
  const { t } = useTranslation()
  const { uploadProfilePicture } = useAuth()
  const userHooks = useFirestore(USER_COLLECTION)

  const [uploading, setUploading] = useState(false)

  useEffect(() => {
    setUploading(false)
  }, [user.photoUrl])

  const handleChange = e => {
    setUploading(true)
    uploadProfilePicture(e.target.files[0], user.id)
  }

  return (
    <div className={clsx(classes.root, className)}>
      {uploading
        ? <img className={classes.image} src={miniloader} alt='miniloader' style={{ borderRadius: '50%' }} />
        : user.photoUrl
          ? <img className={classes.image} src={user.photoUrl} alt='avatar' />
          : <img className={classes.image} src={dropPhoto} alt='drop' />
      }
      <label htmlFor='file' className={classes.actionBtn} id='editImageLabel'>{t('upload')}</label>
      {user.photoUrl && <>
        <Divider orientation='vertical' className={classes.divider} />
        <Link
          className={classes.actionBtn}
          onClick={() => userHooks.updateDoc(user.id, { photoUrl: null })}
        >{t('remove')}</Link>
      </>}
      <input
        type='file'
        name='file'
        id='file'
        className={classes.inputFile}
        onChange={handleChange}
      />
    </div>
  )
}

AvatarInput.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object.isRequired,
}

export default AvatarInput
