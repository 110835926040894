import React from 'react'
import CompanyTable from './CompanyTable'
import Breadcrumbs from '../_library/Breadcrumbs'
import InvitationDisplay from './InvitationDisplay'
import TablePage from '../_library/TablePage'


const CompanyPage = () =>
  <TablePage>
    <InvitationDisplay />
    <Breadcrumbs />
    <CompanyTable />
  </TablePage>

export default CompanyPage
