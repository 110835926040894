import React, { useEffect, useMemo, useState } from 'react'
import { FormControl, FormHelperText, Grid, IconButton, InputLabel, Link, MenuItem, OutlinedInput, Select, Stack, TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { Delete } from '@mui/icons-material'
import SubmitButton from '../_library/SubmitButton'
import { COMPANY_COLLECTION, USER_COLLECTION } from '../../_constants/globals'
import { useNavigate } from 'react-router-dom'
import { HOME } from '../../_constants/routes'
import { increment } from 'firebase/firestore'
import useAuth from '../../hooks/useAuth'
import useFirestore from '../../hooks/useFirestore'


const labelStyle = {
  position: 'static',
  transform: 'none',
  display: 'inline-block',
  width: 150,
  fontWeight: 600,
  lineHeight: '40px',
  verticalAlign: 'bottom',
}

const NewCompanyForm = () => {
  
  const profile = useAuth().getProfile()
  const consultantHook = useFirestore(USER_COLLECTION, { storeAs: 'consultants' })
  const dbConsultants = consultantHook.getDocs()
  const consultantCompanyHook = useFirestore(COMPANY_COLLECTION, { storeAs: 'consultantCompany' })
  const consultantCompanies = consultantCompanyHook.getDocs()
  const companyHook = useFirestore(COMPANY_COLLECTION)
  const navigate = useNavigate()
  
  const [name, setName] = useState(null)
  const [createdAtTime, setCreatedAtTime] = useState(new Date())
  const [licences, setLicences] = useState(1)
  const [consultants, setConsultants] = useState(null)
  const [submitted, setSubmitted] = useState(false)
  const [errors, setErrors] = useState({})
  
  const company = useMemo(() => consultantCompanies ? consultantCompanies[0] : null, [consultantCompanies])
  
  useEffect(() => {setConsultants([profile])}, [profile])
  
  const availableConsultants = useMemo(() => dbConsultants?.filter(u => !consultants?.some(c => c.id === u.id)), [dbConsultants, consultants])
  
  const handleSubmit = e => {
    e.preventDefault()
    if (!name) setErrors(val => ({ ...val, name: 'Le champs doit être rempli' }))
    if (licences < 1) setErrors(val => ({ ...val, licences: 'Il faut au moins une licence pour créer un client' }))
    if (licences > company?.externalLicences) setErrors(val => ({ ...val, licences: 'Vous ne pouvez donner plus de de licences que ce que vous possédez' }))
    if (name && licences >= 1 && licences <= company?.externalLicences) {
      setSubmitted(true)
      return consultantCompanyHook.updateDoc(consultants[0].companyRef.id, {
        externalLicences: increment(-Number(licences)),
      })
        .then(() => companyHook.addDoc({
          name,
          createdAtTime,
          licences: Number(licences),
          consultantUserRef: consultantHook.getDocRef(consultants[0].id),
        }, true))
        .then(() => navigate(HOME))
        .finally(() => setSubmitted(false))
    }
  }
  
  return <form onSubmit={handleSubmit}>
    <Grid container>
      <Grid item md={8}>
        <Stack spacing={3}>
          <FormControl sx={{ display: 'block' }}>
            <InputLabel id='input-name-label' sx={labelStyle}>Nom</InputLabel>
            <OutlinedInput
              value={name || ''}
              onChange={e => setName(e.target.value)}
              variant='outlined'
              size='small'
              disabled={submitted}
              error={!!errors.name}
              sx={{ display: 'inline-block', width: '200px' }}
            />
            {!!errors.name && <FormHelperText sx={{ ml: '150px' }}>{errors.name}</FormHelperText>}
          </FormControl>
          <FormControl sx={{ display: 'block' }}>
            <InputLabel id='datepicker-createdat-label' sx={labelStyle}>Date de création</InputLabel>
            <DatePicker
              inputFormat='dd/MM/yyyy'
              value={createdAtTime}
              onChange={setCreatedAtTime}
              renderInput={params => <TextField {...params} size='small' sx={{ display: 'inline-block', width: '200px' }} />}
              size='small'
              disabled={submitted}
              error={!!errors.createdAt}
            />
          </FormControl>
          <FormControl sx={{ display: 'block' }}>
            <InputLabel id='input-licences-label' sx={labelStyle}>Licences</InputLabel>
            <OutlinedInput
              value={licences}
              onChange={e => e.target.value > 0 && setLicences(e.target.value)}
              variant='outlined'
              size='small'
              disabled={submitted}
              error={!!errors.licences}
              type='number'
              sx={{ display: 'inline-block', width: '200px' }}
            />
            {!!errors.licences && <FormHelperText sx={{ ml: '150px' }}>{errors.licences}</FormHelperText>}
          </FormControl>
          {availableConsultants && consultants?.map((consultant, index) => <FormControl key={consultant.id || 'void'} sx={{ display: 'block' }}>
            <InputLabel id='select-consultant-label' sx={labelStyle}>{index === 0 && 'Consultant(s)'}</InputLabel>
            <Select
              labelId='select-consultant-label'
              id='select-consultant'
              value={consultant.id}
              onChange={e => setConsultants(prev => {prev[index] = dbConsultants.find(u => u.id === e.target.value); return [...prev]} )}
              size='small'
              disabled={submitted}
            >
              <MenuItem key={consultant.id} value={consultant.id}>{consultant.firstname}</MenuItem>,
              {availableConsultants.map(user =>
                <MenuItem key={user.id} value={user.id}>{user.firstname}</MenuItem>,
              )}
            </Select>
            {consultants.length > 1 && <IconButton onClick={() => setConsultants(prev => prev.filter(u => u.id !== consultant.id))}><Delete /></IconButton>}
          </FormControl>)}
          {availableConsultants?.length > 0 && <Link
            onClick={() => setConsultants(prev => [...prev, availableConsultants[0]])}
            aria-disabled={submitted}
            sx={{ mt: 1, ml: '150px', textDecoration: 'underline', cursor: 'pointer', width: 'fit-content' }}
          >Ajouter un autre</Link>}
        </Stack>
      </Grid>
      <Grid item md={4}>
        <SubmitButton typ='submit' disabled={submitted} />
      </Grid>
    </Grid>
  </form>
}

export default NewCompanyForm
