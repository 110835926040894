import React, { useMemo } from 'react'
import { Box, CircularProgress, Grid, Link, Typography } from '@mui/material'
import Page from '../_library/Page'
import BackButton from '../_library/BackButton'
import { useMatch } from 'react-router'
import { EDIT_TEAM } from '../../_constants/routes'
import EditTeamForm from './EditTeamForm'
import cartographieImg from '../../assets/CARDS/card_cartographie.png'
import oneoneImg from '../../assets/CARDS/campfire_duo.png'
import { getTeamProfileType } from '../../_helpers/cardHelper'
import { ONE_TO_ONE_BUILDER, TEAM_PROFILE } from '../../_constants/cardTypes'
import useFirestore from '../../hooks/useFirestore'
import { TEAM_COLLECTION, USER_COLLECTION } from '../../_constants/globals'


const cardLinkStyle = {
  cursor: 'pointer',
  my: 2,
  display: 'block',
  '& img': {
    height: '50px',
    verticalAlign: 'middle',
  },
  '& label': {
    display: 'inline-block',
    verticalAlign: 'middle',
    bgcolor: 'tertiary.main',
    color: 'white',
    py: .5,
    px: 1,
    borderRadius: '5px',
    width: '80%',
    mr: '8%',
    cursor: 'inherit',
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
  },
}

const EditTeamPage = () => {

  const match = useMatch(EDIT_TEAM)
  const teamHooks = useFirestore(TEAM_COLLECTION)
  const teams = teamHooks.getDocs()
  const userHook = useFirestore(USER_COLLECTION)
  const users = userHook.getDocs()

  const team = useMemo(() => match && teams?.find(c => c.id === match.params.id), [match, teams])
  const teamMembers = useMemo(() => users?.length ? team?.memberRefs?.map(ref => users.find(u => u.id === ref.id)) : [], [team, users])
  
  const getCardUrl = type => `${process.env.REACT_APP_BASE_URL}/card/${type}?teamId=${team.id}&consultantMode=true`

  return <Page title={team?.name || ''}>
    <Grid container sx={{
      backgroundColor: 'white',
      position: 'relative',
      minHeight: '300px',
      borderRadius: '22px',
      padding: 5,
      boxShadow: theme => `0 0 10px ${theme.palette.grey[200]}`,
    }}>
      <BackButton sx={{
        position: 'absolute',
        right: 40,
        top: -20,
      }} />
      <Grid item md={8} sx={{ borderRight: theme => `solid 2px ${theme.palette.grey[150]}` }}>
        {team
          ? <EditTeamForm team={team} />
          : <Box sx={{ display: 'flex' }}><CircularProgress /></Box>
        }
      </Grid>
      <Grid item md={4}>
        <Box sx={{ width: '90%', maxWidth: 400, mx: 'auto' }}>
          {team?.id && <>
            <Link href={getCardUrl(TEAM_PROFILE)} target='_blank' sx={cardLinkStyle}>
              <label>Voir la cartographie &gt;</label>
              <img src={cartographieImg} alt='card talent' />
            </Link>
            <Link href={getCardUrl(ONE_TO_ONE_BUILDER)} target='_blank' sx={cardLinkStyle}>
              <label>Feu de camp duo &gt;</label>
              <img src={oneoneImg} alt='card management' />
            </Link>
            <Typography color='primary.dark' fontWeight={600} mt={3} mb={2}>Profil d&apos;équipe : {teamMembers && getTeamProfileType(teamMembers.filter(u => u?.testResults))}</Typography>
            <Typography color='primary.dark' fontWeight={600}>Membres :</Typography>
            {teamMembers?.map(user => <Typography key={user.id} color='grey.500'>
              {`${user.profileType || '???'} - ${user.firstname}` || user.id}
            </Typography>)}
          </>}
        </Box>
      </Grid>
    </Grid>
  </Page>
}

export default EditTeamPage
