import React, { Fragment, useMemo, useState } from 'react'
import { Box, Button, Typography } from '@mui/material'
import MUIDataTable from 'mui-datatables'
import DeleteButton from '../_library/DeleteButton'
import { Edit } from '@mui/icons-material'
import { TEAM_COLLECTION, USER_COLLECTION } from '../../_constants/globals'
import { makeStyles } from '@mui/styles'
import LoadingBoussole from '../_library/LoadingBoussole'
import TableToolbar from '../company/TableToolbar'
import { EDIT_TEAM, EDIT_USER, NEW_USER } from '../../_constants/routes'
import { Link, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { getRoles } from '../../_helpers/helpers'
import Avatar from '../_library/Avatar'
import { deleteField } from 'firebase/firestore'
import useFirestore from '../../hooks/useFirestore'


const useStyles = makeStyles(theme => {
  const HEADER_RADIUS = 40
  return {
    root: {
      color: 'black',
      backgroundColor: theme.palette.background.default,
      '& .MuiTable-root': {
        borderCollapse: 'separate',
      },
      '& .MuiTableCell-root': {
        padding: theme.spacing(1),
      },
      '& th.MuiTableCell-head': {
        backgroundColor: theme.palette.grey[150],
      },
      '& th.MuiTableCell-head:first-child': {
        borderTopLeftRadius: HEADER_RADIUS,
        borderBottomLeftRadius: HEADER_RADIUS,
      },
      '& th.MuiTableCell-head:last-child': {
        borderTopRightRadius: HEADER_RADIUS,
        borderBottomRightRadius: HEADER_RADIUS,
      },
    },
    actionBtn: {
      color: theme.palette.tertiary.main,
      minWidth: 40,
    },
  }
})

const UserTable = ({ company, team }) => {
  
  const classes = useStyles()
  const userHooks = useFirestore(USER_COLLECTION)
  const users = userHooks.getDocs().filter(u => (!company || u.companyRef?.id === company.id) && (!team || u.teamRefs?.some(ref => ref.id === team.id)))
  const navigate = useNavigate()
  const teamHook = useFirestore(TEAM_COLLECTION)
  const teams = teamHook.getDocs()
  
  const [selectedUsers, setSelectedUsers] = useState([])
  
  const data = useMemo(() =>
    users?.map(u =>
      [
        u,
        { photoUrl: u.photoUrl, firstname: u.firstname, id: u.id },
        u.email,
        u.company?.name,
        u.teamRefs,
        u.accessLevel,
        u.profileType,
        u.rating,
        u.id,
      ],
    ), [users])
  
  const handleDelete = uid => userHooks.updateDoc(uid, {
    companyRef: deleteField(),
    consultantCompanyRef: deleteField(),
    consultantUserRef: deleteField(),
  })
  
  if (!teams) return <LoadingBoussole />
  else return (
    <MUIDataTable
      className={classes.root}
      data={data}
      columns={[
        { name: 'object', options: { filter: false, sort: false, display: 'excluded', print: false, searchable: false, download: false } },
        {
          name: 'USER',
          options: {
            sortCompare: order => ({ data: { firstname: firstname1 } }, { data: { firstname: firstname2 } }) =>
              order === 'asc'
                ? firstname1.localeCompare(firstname2)
                : firstname2.localeCompare(firstname1),
            customBodyRender: ({ photoUrl, firstname, id }) => // eslint-disable-line react/prop-types
              <Box onClick={() => navigate(EDIT_USER.replace(':id', id))} sx={{ cursor: 'pointer' }}>
                <Avatar photoUrl={photoUrl} sx={{
                  height: '50px',
                  width: '50px',
                  display: 'inline-block',
                  verticalAlign: 'middle',
                  mr: 1,
                }} />&nbsp;
                {firstname}
              </Box>,
          },
        },
        { name: 'EMAIL' },
        { name: 'CLIENT' },
        {
          name: 'TEAMS',
          options: {
            // eslint-disable-line react/prop-types
            customBodyRender: teamRefs => teamRefs?.map((ref, index) => <Fragment key={ref.id}>
              {index !== 0 && ', '}
              <Link to={EDIT_TEAM.replace(':id', ref.id)}>{teams.find(t => t.id === ref.id)?.name}</Link>
            </Fragment>),
          },
        },
        { name: 'DROITS', options: { customBodyRender: accessLevel => getRoles().find(r => r.accessLevel === accessLevel)?.title } },
        { name: 'PROFILE' },
        { name: 'RATING' },
        { name: 'EDIT',
          options: {
            customBodyRender: id => // eslint-disable-line react/display-name
              <>
                <Button className={classes.actionBtn} onClick={() => navigate(EDIT_USER.replace(':id', id))}>
                  <Edit />
                </Button>
                <DeleteButton className={classes.actionBtn} deleteAction={() => handleDelete(id)} />
              </>,
          },
        },
      ]}
      options={{
        responsive: 'simple',
        pagination: false,
        viewColumns: false,
        print: false,
        search: false,
        download: false,
        elevation: 0,
        onRowSelectionChange: (currentRowsSelected, allRowsSelected) => setSelectedUsers(allRowsSelected.map(({ dataIndex }) => users[dataIndex])),
        selectToolbarPlacement: 'none',
        rowsSelected: selectedUsers.map(u => users.findIndex(user => user.id === u.id)),
        textLabels: {
          body: {
            noMatch: <Typography>La liste est vide. <Link to={NEW_USER} key='emptyList'>Cliquez ici pour créer le premier utilisateur</Link></Typography>,
          },
        },
      }}
      components={{
        TableToolbar: () => <TableToolbar selectedObjects={selectedUsers} resetSelect={() => setSelectedUsers([])} collection={USER_COLLECTION} />,
      }}
    />
  )
}

UserTable.propTypes = {
  company: PropTypes.object,
  team: PropTypes.object,
}

export default UserTable
