import React, { useMemo } from 'react'
import { Box, CircularProgress, Grid, Link, Typography } from '@mui/material'
import Page from '../_library/Page'
import BackButton from '../_library/BackButton'
import { useMatch } from 'react-router'
import { EDIT_USER } from '../../_constants/routes'
import EditUserForm from './EditUserForm'
import positionImg from '../../assets/CARDS/card_positionnement.png'
import goldImg from '../../assets/CARDS/gold_card.png'
import communicationImg from '../../assets/CARDS/card_communication.png'
import managementImg from '../../assets/CARDS/card_management.png'
import talentExtImg from '../../assets/CARDS/card_talent_equipier.png'
import { COMMUNICATION, MANAGEMENT, POSITION, TALENT, TALENT_EXT } from '../../_constants/cardTypes'
import useFirestore from '../../hooks/useFirestore'
import { USER_COLLECTION } from '../../_constants/globals'


const cardLinkStyle = {
  verticalAlign: 'middle',
  cursor: 'pointer',
  my: 1,
  display: 'block',
  '& img': {
    width: '12%',
  },
  '& label': {
    display: 'inline-block',
    bgcolor: 'tertiary.main',
    color: 'white',
    py: .5,
    px: 1,
    borderRadius: '5px',
    width: '80%',
    marginLeft: '8%',
    cursor: 'inherit',
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
  },
}

const EditUserPage = () => {

  const match = useMatch(EDIT_USER)
  const userHook = useFirestore(USER_COLLECTION)
  const users = userHook.getDocs()

  const user = useMemo(() => match && users?.find(c => c.id === match.params.id), [match, users])
  
  const getCardUrl = type => `${process.env.REACT_APP_BASE_URL}/card/${type}?uid=${user.id}&consultantMode=true`
  
  return <Page title={user?.firstname || ''}>
    <Grid container sx={{
      backgroundColor: 'white',
      position: 'relative',
      minHeight: '300px',
      borderRadius: '22px',
      p: 5,
      boxShadow: theme => `0 0 10px ${theme.palette.grey[200]}`,
    }}>
      <BackButton sx={{ position: 'absolute', right: '40px', top: '-20px' }} />
      <Grid item md={8} sx={{ borderRight: 'solid 2px', borderColor: 'grey.100' }}>
        {user
          ? <EditUserForm user={user} />
          : <Box sx={{ display: 'flex' }}><CircularProgress /></Box>
        }
      </Grid>
      <Grid item md={4}>
        <Box sx={{ width: '90%', maxWidth: 400, mx: 'auto' }}>
          <Typography variant='body1' fontSize='.9rem' sx={{ '& > strong': { color: 'grey.500', fontSize: '1rem' } }}>
            Status: <strong>{user?.onboardingStatus || 'En attente...'}</strong>
          </Typography>
          {user?.profileType && <>
            <Link href={getCardUrl(TALENT)} target='_blank' sx={cardLinkStyle}>
              <img src={goldImg} alt='card talent' />
              <label>Talent d&apos;or &gt;</label>
            </Link>
            <Link href={getCardUrl(POSITION)} target='_blank' sx={cardLinkStyle}>
              <img src={positionImg} alt='card position' />
              <label>Position clé &gt;</label>
            </Link>
            <Link href={getCardUrl(COMMUNICATION)} target='_blank' sx={cardLinkStyle}>
              <img src={communicationImg} alt='card communication' />
              <label>Style de communication &gt;</label>
            </Link>
            <Link href={getCardUrl(MANAGEMENT)} target='_blank' sx={cardLinkStyle}>
              <img src={managementImg} alt='card management' />
              <label>Style de management &gt;</label>
            </Link>
            <Link href={getCardUrl(TALENT_EXT + '/' + user.id)} target='_blank' sx={cardLinkStyle}>
              <img src={talentExtImg} alt='card talent-ext' />
              <label>Résumé du profil &gt;</label>
            </Link>
          </>}
        </Box>
      </Grid>
    </Grid>
  </Page>
}

export default EditUserPage
