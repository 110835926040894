import React, { useEffect, useMemo, useState } from 'react'
import { IconButton, Typography } from '@mui/material'
import MUIDataTable from 'mui-datatables'
import DeleteButton from '../_library/DeleteButton'
import { CopyAll, Edit } from '@mui/icons-material'
import { CAMPFIRE_COLLECTION, USER_COLLECTION } from '../../_constants/globals'
import LoadingBoussole from '../_library/LoadingBoussole'
import TableToolbar from './TableToolbar'
import { Link, useNavigate } from 'react-router-dom'
import useFirestore from '../../hooks/useFirestore'
import { EDIT_CAMPFIRE } from '../../_constants/routes'
import { format } from 'date-fns'
import { serverTimestamp } from 'firebase/firestore'
import useAuth from '../../hooks/useAuth'
import { omit } from 'lodash'


const CampfireTable = () => {
  
  const campfireHooks = useFirestore(CAMPFIRE_COLLECTION)
  const campfires = campfireHooks.getDocs()
  const userHooks = useFirestore(USER_COLLECTION)
  const navigate = useNavigate()
  const profile = useAuth().getProfile()
  
  const [selectedCampfires, setSelectedCampfires] = useState([])
  
  useEffect(() => {
    campfireHooks.listen({
      where: [['consultantUserRef', '==', userHooks.getDocRef(profile.id)]],
      orderBy: [['_createdAtTime', 'asc']],
    })
    return campfireHooks.unsubscribe
  }, [])
  
  const data = useMemo(() =>
    campfires?.map(cf =>
      [
        cf,
        cf.company?.name,
        cf.team?.name,
        cf.comment,
        cf._createdAtTime,
        cf.id,
      ],
    ), [campfires])
  
  const clone = source => campfireHooks.addDoc({
    ...omit(source, 'id'),
    _createdAtTime: serverTimestamp(),
    status: null,
  })
  
  if (!campfires) return <LoadingBoussole />
  else return <>
    <MUIDataTable
      data={data}
      columns={[
        { name: 'object', options: { filter: false, sort: false, display: 'excluded', print: false, searchable: false, download: false } },
        { name: 'Client' },
        { name: 'Team' },
        { name: 'Comment' },
        { name: 'Créé le', options: { customBodyRender: val => val && format(val, 'dd/MM/yyyy') } },
        { name: 'Editer',
          options: {
            customBodyRender: (id, { rowData }) => // eslint-disable-line react/display-name
              <>
                <IconButton color='tertiary' onClick={() => navigate(EDIT_CAMPFIRE.replace(':id', id))}><Edit /></IconButton>
                <IconButton color='tertiary' onClick={() => clone(rowData[0])}><CopyAll /></IconButton>
                <DeleteButton color='tertiary' deleteAction={() => campfireHooks.deleteDoc(id)} />
              </>,
          },
        },
      ]}
      options={{
        responsive: 'simple',
        pagination: false,
        viewColumns: false,
        print: false,
        search: false,
        download: false,
        elevation: 0,
        onRowSelectionChange: (currentRowsSelected, allRowsSelected) => setSelectedCampfires(allRowsSelected.map(({ dataIndex }) => campfires[dataIndex])),
        selectToolbarPlacement: 'none',
        rowsSelected: selectedCampfires.map(cf => campfires.findIndex(campfire => campfire.id === cf.id)),
        textLabels: {
          body: {
            noMatch: <Typography>La liste des feux de camp est vide. <Link to={EDIT_CAMPFIRE.replace(':id', 'team')} key='emptyList'>Cliquez ici pour créer votre premier</Link></Typography>,
          },
        },
      }}
      components={{
        TableToolbar: () => <TableToolbar selectedItems={selectedCampfires} resetSelect={() => setSelectedCampfires([])} />,
      }}
    />
  </>
}

export default CampfireTable
