import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Select, MenuItem, ListItemText, Tooltip, Box } from '@mui/material'
import { AddCircle } from '@mui/icons-material'
import { NEW_COMPANY, NEW_TEAM, NEW_USER } from '../../_constants/routes'
import { useNavigate } from 'react-router-dom'
import useFirestore from '../../hooks/useFirestore'
import { COMPANY_COLLECTION } from '../../_constants/globals'


const TableToolbar = ({ selectedObjects, resetSelect }) => {

  const navigate = useNavigate()
  const companyHook = useFirestore(COMPANY_COLLECTION)
  const companies = companyHook.getDocs()

  const [selectedAction, setSelectedAction] = useState(null)

  const handleAction = () => {
    switch (selectedAction) {
      case 'deleteCompany':
        return companyHook.batchDeleteDocAction(selectedObjects.map(c => c.id)).then(resetSelect)
      default:
        return null
    }
  }

  return (
    <Box sx={{ width: '100%', mb: 2 }}>
      <Select
        variant='outlined'
        value={selectedAction || ''}
        onChange={e => setSelectedAction(e.target.value)}
        sx={{
          minWidth: 150,
          height: 36,
          mr: 2,
          '& .MuiInputBase-input': {
            p: 1,
          },
        }}
      >
        <MenuItem value='deleteCompany'>
          <ListItemText primary='Supprimer' />
        </MenuItem>
      </Select>
      <Button variant='contained' onClick={handleAction} disabled={selectedObjects?.length === 0 || !selectedAction} sx={{
        borderRadius: 40,
        boxShadow: 'none',
        color: 'white',
        textTransform: 'initial',
        bgcolor: 'grey.200',
      }}>Appliquer</Button>
      <Box sx={{ float: 'right' }}>
        <Tooltip title='Contactez nous pour ajouter de nouveaux consultants.'>
          <span><Button disabled><AddCircle />&nbsp;consultant</Button></span>
        </Tooltip>
        <Button color='secondary' onClick={() => navigate(NEW_COMPANY)}><AddCircle />&nbsp;Client</Button>
        <Button color='secondary' onClick={() => navigate(NEW_TEAM)} disabled={!companies?.length}><AddCircle />&nbsp;Team</Button>
        <Button color='secondary' onClick={() => navigate(NEW_USER)} disabled={!companies?.length}><AddCircle />&nbsp;User</Button>
      </Box>
    </Box>
  )
}

TableToolbar.propTypes = {
  selectedObjects: PropTypes.array,
  resetSelect: PropTypes.func.isRequired,
  collection: PropTypes.string.isRequired,
}

export default TableToolbar
