import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Select, MenuItem, ListItemText, Box } from '@mui/material'
import { AddCircle } from '@mui/icons-material'
import { EDIT_CAMPFIRE } from '../../_constants/routes'
import { useNavigate } from 'react-router-dom'
import { CAMPFIRE_COLLECTION } from '../../_constants/globals'
import useFirestore from '../../hooks/useFirestore'


const TableToolbar = ({ selectedObjects, resetSelect }) => {

  const navigate = useNavigate()
  const campfireHooks = useFirestore(CAMPFIRE_COLLECTION)

  const [selectedAction, setSelectedAction] = useState(null)

  const handleAction = () => {
    switch (selectedAction) {
      case 'delete':
        return campfireHooks.batchDeleteDocAction(selectedObjects.map(c => c.id)).then(resetSelect)
      default:
        return null
    }
  }

  return (
    <Box sx={{ width: '100%', mb: 2 }}>
      <Select
        variant='outlined'
        value={selectedAction || ''}
        onChange={e => setSelectedAction(e.target.value)}
        sx={{ minWidth: 150, height: 36, mr: 2, '& .MuiInputBase-input': { p: 1 } }}
      >
        <MenuItem value='delete'>
          <ListItemText primary='Supprimer' />
        </MenuItem>
      </Select>
      <Button variant='contained' onClick={handleAction} disabled={selectedObjects?.length === 0 || !selectedAction} sx={{
        borderRadius: 40,
        boxShadow: 'none',
        color: 'white',
        textTransform: 'initial',
        bgcolor: 'grey.200',
      }}>Appliquer</Button>
      <Box sx={{ float: 'right' }}>
        <Button color='secondary' onClick={() => navigate(EDIT_CAMPFIRE.replace(':id', 'team'))} sx={{ fontWeight: 'bold' }}><AddCircle />&nbsp;Feu de camp</Button>
      </Box>
    </Box>
  )
}

TableToolbar.propTypes = {
  selectedObjects: PropTypes.array,
  resetSelect: PropTypes.func.isRequired,
}

export default TableToolbar
