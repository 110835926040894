export const HOME = '/'
export const LOGIN = '/auth'
export const CLIENTS = '/clients'
export const NEW_COMPANY = '/newClient'
export const EDIT_COMPANY = '/editClient/:id'
export const TEAMS = '/teams'
export const NEW_TEAM = '/newTeam'
export const EDIT_TEAM = '/editTeam/:id'
export const USERS = '/users'
export const NEW_USER = '/newUser'
export const EDIT_USER = '/editUser/:id'
export const CAMPFIRES = '/campfires'
export const EDIT_CAMPFIRE = '/campfire/:id'
