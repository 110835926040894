import React, { useMemo } from 'react'
import { Box, CircularProgress, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import Page from '../_library/Page'
import { useMatch } from 'react-router'
import { EDIT_COMPANY } from '../../_constants/routes'
import EditCompanyForm from './EditCompanyForm'
import FormContainer from '../_library/FormContainer'
import InvitationDisplay from './InvitationDisplay'
import useFirestore from '../../hooks/useFirestore'
import { COMPANY_COLLECTION } from '../../_constants/globals'


const useStyles = makeStyles(theme => ({
  docId: {
    textAlign: 'center',
    fontSize: '.9rem',
    '& > strong': {
      color: theme.palette.grey[500],
      fontSize: '1rem',
    },
  },
}))

const EditCompanyPage = () => {

  const classes = useStyles()
  const match = useMatch(EDIT_COMPANY)
  const companyHook = useFirestore(COMPANY_COLLECTION)
  const companies = companyHook.getDocs()

  const company = useMemo(() => match && companies?.find(c => c.id === match.params.id), [match, companies])

  return <Page title={company?.name || ''}>
    <InvitationDisplay />
    <FormContainer form={company ? <EditCompanyForm company={company} /> : <Box sx={{ display: 'flex' }}><CircularProgress /></Box>}>
      <Typography variant='body1' className={classes.docId}>ID: <strong>{company?.id}</strong></Typography>
    </FormContainer>
  </Page>
}

export default EditCompanyPage
