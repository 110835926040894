import React from 'react'
import TablePage from '../_library/TablePage'
import InvitationDisplay from '../company/InvitationDisplay'
import CampfireTable from './CampfireTable'


const CampfirePage = () =>
  <TablePage>
    <InvitationDisplay />
    <CampfireTable />
  </TablePage>

export default CampfirePage
