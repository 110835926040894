import * as qs from 'querystring'

/**
 * Parses a string of type url params into an object
 *
 * @param urlParams
 *
 * @example
 * input : ?mode=resetPassword&oobCode=Ae5ee&apiKey=AIzaSyC5U&lang=en
 * output : {
 *   apiKey: "AIzaSy",
 *   lang: "en",
 *   mode: "resetPassword",
 *   oobCode: "Ae5ee"
 * }
 */
export const parseParams = urlParams => qs.parse(urlParams.replace('?', ''))

export const getRoles = () => [
  { title: 'Collaborateur', accessLevel: 25 },
  { title: 'Manager', accessLevel: 50 },
  { title: 'Admin', accessLevel: 75 },
]
