import React from 'react'
import PropTypes from 'prop-types'
import { Badge, Tooltip } from '@mui/material'


const InfoBadge = ({ title, display = true, children }) => display
  ? <Tooltip title={title}>
    <Badge badgeContent='?' color='info' sx={{ width: 'fit-content' }} slotProps={{ badge: { sx: { right: '-12px', cursor: 'default' } } }}>
      {children}
    </Badge>
  </Tooltip>
  : children


InfoBadge.propTypes = {
  title: PropTypes.string.isRequired,
  display: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
}

export default InfoBadge
