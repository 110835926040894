import { createTheme, responsiveFontSizes } from '@mui/material/styles'
import RalewayTtf from './assets/fonts/Raleway/Raleway-Medium.ttf'

const palette = {
  primary: {
    main: '#3DADAD',
    light: '#3CADAC',
    dark: '#343051',
  },
  secondary: {
    main: '#F05',
  },
  tertiary: {
    main: '#d17838',
    light: '#D99362',
    dark: '#BE4F23',
  },
  grey: {
    100: '#F8F0D7',
    150: '#E8E8E8',
    200: '#C8C8C8',
    500: '#9e9bac',
    700: '#464646',
  },
  background: {
    default: '#F8F8F8',
    blue: '#3DADAD',
    deepBlue: '#343051',
  },
  divider: '#343051',
  action: {
    active: '#ddd',
  },
  error: {
    main: '#F05',
  },
}

const theme = responsiveFontSizes(createTheme({
  palette,
  typography: {
    fontFamily: ['Raleway'],
    h1: {
      color: '#3DADAD',
      fontSize: '3rem',
      fontWeight: 600,
      marginBottom: 50,
    },
    h2: {
      fontSize: '2.3rem',
      fontWeight: 700,
      color: '#343051',
      marginBottom: 32,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Raleway';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Raleway'), local('Raleway-Regular'), url(${RalewayTtf}) format('ttf');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          borderRadius: 5,
          padding: '2px 8px',
          textTransform: 'initial',
          fontWeight: 'bold',
          justifyContent: 'start',
          '&:disabled': {
            backgroundColor: palette.grey[200],
            color: 'white',
          },
          ...(ownerState.variant === 'contained' && {
            color: palette.grey[100],
          }),
          ...(ownerState.color === 'tertiary' && {
            backgroundColor: palette.tertiary.main,
            '&:hover': {
              backgroundColor: palette.tertiary.main,
            },
          }),
        }),
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          textTransform: 'initial',
          '&.MuiPickersDay-root:focus.Mui-selected': {
            backgroundColor: '#3CADAC',
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: palette.grey[150],
          borderWidth: '1.5px',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: palette.error.main,
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'none',
      },
      styleOverrides: {
        root: {
          color: 'black',
          fontSize: '1rem',
          '&.disabled': {
            color: 'grey',
            cursor: 'default',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          minWidth: '120px',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
          textTransform: 'none',
          padding: 0,
          fontSize: 'xx-large',
          margin: '8px 8px 0',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          height: '3px',
          borderRadius: '2px',
        },
      },
    },
  },
}))

export default theme
