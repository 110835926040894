import React, { useEffect, useMemo, useState } from 'react'
import { FormControl, FormHelperText, Grid, InputLabel, MenuItem, OutlinedInput, Select, Stack, TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { DatePicker } from '@mui/x-date-pickers'
import { useDispatch, useSelector } from 'react-redux'
import SubmitButton from '../_library/SubmitButton'
import { COMPANY_COLLECTION, TEAM_COLLECTION, USER_COLLECTION } from '../../_constants/globals'
import { useNavigate } from 'react-router-dom'
import { TEAMS } from '../../_constants/routes'
import { pickBy } from 'lodash'
import useFirestore from '../../hooks/useFirestore'
import { createNotification } from '../../store/actions/notificationActions'
import { ERROR, SUCCESS } from '../../store/types'


const useStyles = makeStyles(theme => ({
  formControl: {
    display: 'block',
  },
  label: {
    position: 'static',
    transform: 'none',
    display: 'inline-block',
    width: 150,
    fontWeight: 600,
    lineHeight: '40px',
    verticalAlign: 'bottom',
  },
  input: {
    display: 'inline-block',
    width: 200,
  },
  error: {
    marginLeft: 150,
  },
  caption: {
    verticalAlign: 'super',
    color: theme.palette.grey[300],
  },
  addConsultantBtn: {
    textDecoration: 'underline',
    cursor: 'pointer',
    width: 'fit-content',
  },
}))

const NewTeamForm = () => {

  const classes = useStyles()
  const navigate = useNavigate()
  const selectedCompanyId = useSelector(state => state.data.selectedCompanyId)
  const companyHook = useFirestore(COMPANY_COLLECTION)
  const companies = companyHook.getDocs()
  const teamHook = useFirestore(TEAM_COLLECTION)
  const userHook = useFirestore(USER_COLLECTION)
  const users = userHook.getDocs()
  const dispatch = useDispatch()

  const [name, setName] = useState(null)
  const [createdAtTime, setCreatedAtTime] = useState(new Date())
  const [company, setCompany] = useState(null)
  const [manager, setManager] = useState(null)
  const [campFire1, setCampFire1] = useState(new Date())
  const [campFire2, setCampFire2] = useState(new Date())
  const [submitted, setSubmitted] = useState(false)
  const [errors, setErrors] = useState({})

  useEffect(() => {
    if (!company && companies) {
      selectedCompanyId
        ? setCompany(companies?.find(c => c.id === selectedCompanyId))
        : setCompany(companies[0])
    }
  }, [companies, selectedCompanyId])

  const filteredUsers = useMemo(() => users?.filter(u => u.companyRef.id === company?.id), [users, company])
  
  useEffect(() => {
    filteredUsers && setManager(filteredUsers[0])
  }, [filteredUsers])

  const handleSubmit = e => {
    e.preventDefault()
    if (!name) setErrors(val => ({ ...val, name: 'Name field must be completed' }))
    if (name) {
      setSubmitted(true)
      teamHook.addDoc(pickBy({
        name,
        createdAtTime,
        companyRef: companyHook.getDocRef(company.id),
        managerRefs: manager && [userHook.getDocRef(manager.id)],
        memberRefs: manager && [userHook.getDocRef(manager.id)],
        safeCocoonDate: campFire1,
        teamBuildingDate: campFire2,
      }), true)
        .then(() => dispatch(createNotification(SUCCESS, 'notifications.teams.create.success')))
        .then(() => navigate(TEAMS))
        .catch(() => dispatch(createNotification(ERROR, 'notifications.create.error')))
        .finally(() => setSubmitted(false))
    }
  }

  return (
    <form onSubmit={handleSubmit} className={classes.root}>
      <Grid container>
        <Grid item md={8}>
          <Stack spacing={3}>
            <FormControl className={classes.formControl}>
              <InputLabel id='input-name-label' className={classes.label}>Nom</InputLabel>
              <OutlinedInput
                value={name || ''}
                onChange={e => setName(e.target.value)}
                variant='outlined'
                className={classes.input}
                size='small'
                disabled={submitted}
                error={!!errors.name}
              />
              {!!errors.name && <FormHelperText className={classes.error}>{errors.name}</FormHelperText>}
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel id='datepicker-createdAtTime-label' className={classes.label}>Date de création</InputLabel>
              <DatePicker
                inputFormat='dd/MM/yyyy'
                value={createdAtTime}
                onChange={setCreatedAtTime}
                renderInput={params => <TextField {...params} className={classes.input} size='small' />}
                size='small'
                disabled={submitted}
                error={!!errors.createdAtTime}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel id='select-company-label' className={classes.label}>Client</InputLabel>
              {company && <Select
                labelId='select-company-label'
                id='select-company'
                value={company.id}
                onChange={e => setCompany(companies.find(c => c.id === e.target.value))}
                size='small'
                disabled={submitted}
              >
                {companies.map(c => <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>)}
              </Select>}
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel id='select-manager-label' className={classes.label}>Manager</InputLabel>
              <Select
                labelId='select-manager-label'
                id='select-manager'
                value={manager?.id || ''}
                onChange={e => setManager(users.find(u => u.id === e.target.value))}
                size='small'
                disabled={submitted}
              >
                <MenuItem value=''><em>Sans manager</em></MenuItem>
                {filteredUsers?.map(u => <MenuItem key={u.id} value={u.id}>{u.firstname}</MenuItem>)}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel id='datepicker-campfire1-label' className={classes.label}>Feu de camp 1</InputLabel>
              <DatePicker
                inputFormat='dd/MM/yyyy'
                value={campFire1}
                onChange={setCampFire1}
                renderInput={params => <TextField {...params} className={classes.input} size='small' />}
                size='small'
                disabled={submitted}
                error={!!errors.campFire1}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel id='datepicker-campfire1-label' className={classes.label}>Feu de camp 2</InputLabel>
              <DatePicker
                inputFormat='dd/MM/yyyy'
                value={campFire2}
                onChange={setCampFire2}
                renderInput={params => <TextField {...params} className={classes.input} size='small' />}
                size='small'
                disabled={submitted}
                error={!!errors.campFire2}
              />
            </FormControl>
          </Stack>
        </Grid>
        <Grid item md={4}>
          <SubmitButton disabled={submitted} />
        </Grid>
      </Grid>
    </form>
  )
}

export default NewTeamForm
