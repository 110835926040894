import React from 'react'
import PropTypes from 'prop-types'
import { Backdrop } from '@mui/material'
import loadingBoussole from '../../assets/loadingBoussole.gif'


export const LoadingBoussole = ({ noBackground }) =>
  noBackground ?
    <img
      src={loadingBoussole}
      alt='boussole loader'
    /> :
    <Backdrop open sx={{
      zIndex: theme => theme.zIndex.drawer + 1,
      backgroundColor: 'background.default',
    }}>
      <img
        src={loadingBoussole}
        alt='boussole loader'
      />
    </Backdrop>

LoadingBoussole.propTypes = {
  noBackground: PropTypes.bool,
}

export default LoadingBoussole
