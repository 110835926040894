import React, { useEffect, useMemo, useState } from 'react'
import { Box, FormControl, FormHelperText, Grid, IconButton, InputLabel, Link, MenuItem, OutlinedInput, Select, Stack } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useDispatch } from 'react-redux'
import SubmitButton from '../_library/SubmitButton'
import { CARD_COLLECTION, COMPANY_COLLECTION, TEAM_COLLECTION, USER_COLLECTION } from '../../_constants/globals'
import PropTypes from 'prop-types'
import { getRoles } from '../../_helpers/helpers'
import { pickBy, union } from 'lodash'
import { Delete, Star } from '@mui/icons-material'
import clsx from 'clsx'
import StyledRating from '../_library/StyledRating'
import AvatarInput from '../_library/AvatarInput'
import { arrayUnion, arrayRemove } from 'firebase/firestore'
import { ERROR, SUCCESS } from '../../store/types'
import { createNotification } from '../../store/actions/notificationActions'
import useAuth from '../../hooks/useAuth'
import useFirestore from '../../hooks/useFirestore'


const useStyles = makeStyles(theme => ({
  formControl: {
    display: 'block',
  },
  label: {
    position: 'static',
    transform: 'none',
    display: 'inline-block',
    width: 150,
    fontWeight: 600,
    lineHeight: '40px',
    verticalAlign: 'bottom',
  },
  input: {
    display: 'inline-block',
    width: 200,
  },
  error: {
    marginLeft: 150,
  },
  caption: {
    verticalAlign: 'super',
    color: theme.palette.grey[300],
  },
  actionBtn: {
    textDecoration: 'underline',
    cursor: 'pointer',
    width: 'fit-content',
    color: theme.palette.grey[500],
    margin: theme.spacing(1, 0, 1, '150px'),
    display: 'block',
  },
}))

const EditUserForm = ({ user }) => {
  
  const { resendInviteEmailAction } = useAuth()
  const classes = useStyles()
  const dispatch = useDispatch()
  const companyHook = useFirestore(COMPANY_COLLECTION)
  const companies = companyHook.getDocs()
  const teamHook = useFirestore(TEAM_COLLECTION)
  const dbTeams = teamHook.getDocs()
  const cardHook = useFirestore(CARD_COLLECTION)
  const userHook = useFirestore(USER_COLLECTION)
  
  const [photoUrl, setPhotoUrl] = useState(null)
  const [firstname, setFirstname] = useState(null)
  const [gender, setGender] = useState(null)
  const [email, setEmail] = useState(null)
  const [company, setCompany] = useState(null)
  const [accessLevel, setAccessLevel] = useState(null)
  const [teams, setTeams] = useState([])
  const [submitted, setSubmitted] = useState(false)
  const [errors, setErrors] = useState({})
  const [talentCard, setTalentCard] = useState(null)
  
  const companyTeams = useMemo(() => dbTeams?.filter(t => t.companyRef.id === user.companyRef?.id), [dbTeams, user])
  const availableTeams = useMemo(() => companyTeams?.filter(u => !teams?.some(t => t.id === u.id)), [companyTeams, teams])
  
  useEffect(() => {
    setPhotoUrl(user.photoUrl)
    setFirstname(user.firstname)
    setGender(user.gender)
    setEmail(user.email)
    setCompany(companies.find(c => c.id === user.companyRef.id))
    setAccessLevel(user.accessLevel)
    setTeams(companyTeams.filter(t => t.memberRefs?.some(ref => ref.id === user.id)).map(t => ({ ...t, manager: t.managerRefs?.some(ref => ref.id === user.id) })))
    cardHook.fetch({
      where: [
        ['userRef', '==', userHook.getDocRef(user.id)],
        ['type', '==', 'talent-ext'],
      ],
    }).then(cards => setTalentCard(cards[0]))
  }, [user])
  
  const handleSubmit = e => {
    e.preventDefault()
    if (!firstname) setErrors(val => ({ ...val, name: 'Firstname field must not be empty' }))
    if (firstname) {
      setSubmitted(true)
      const userRef = userHook.getDocRef(user.id)
      const batch = userHook.getBatch()
      batch.update(userRef, pickBy({
        photoUrl,
        firstname,
        gender,
        email,
        companyRef: company.id !== user.companyRef.id && companyHook.getDocRef(company.id),
        accessLevel,
      }))
      union(teams.map(t => t.id), user.teamRefs?.map(t => t.id)).forEach(teamId => batch.update(teamHook.getDocRef(teamId), {
        memberRefs: teams.map(t => t.id).includes(teamId) ? arrayUnion(userRef) : arrayRemove(userRef),
        managerRefs: teams.map(t => t.id).includes(teamId) && teams.find(t => t.id === teamId).manager ? arrayUnion(userRef) : arrayRemove(userRef),
      }))
      return batch.commit()
        .then(() => dispatch(createNotification(SUCCESS, 'notifications.users.update.success')))
        .catch(() => dispatch(createNotification(ERROR, 'notifications.update.error')))
        .finally(() => setSubmitted(false))
    }
  }
  
  return (
    <form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item md={8}>
          <Stack spacing={3}>
            <FormControl className={classes.formControl}>
              <InputLabel id='input-photo-label' className={classes.label}>Photo de profil</InputLabel>
              <AvatarInput user={user} />
              {!!errors.photoUrl && <FormHelperText className={classes.error}>{errors.photoUrl}</FormHelperText>}
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel id='input-firstname-label' className={classes.label}>Prénom</InputLabel>
              <OutlinedInput
                value={firstname || ''}
                onChange={e => setFirstname(e.target.value)}
                variant='outlined'
                className={classes.input}
                size='small'
                disabled={submitted}
                error={!!errors.firstname}
              />
              {!!errors.firstname && <FormHelperText className={classes.error}>{errors.firstname}</FormHelperText>}
            </FormControl>
            {user.profileType && <FormControl className={classes.formControl}>
              <InputLabel id='select-gender-label' className={classes.label}>Genre</InputLabel>
              <Select
                labelId='select-gender-label'
                id='select-gender'
                value={gender || ''}
                onChange={e => setGender(e.target.value)}
                size='small'
                disabled={submitted}
              >
                <MenuItem value='man'>Homme</MenuItem>
                <MenuItem value='woman'>Femme</MenuItem>
              </Select>
            </FormControl>}
            <FormControl className={classes.formControl}>
              <InputLabel id='input-email-label' className={classes.label}>Email</InputLabel>
              <OutlinedInput
                value={email || ''}
                onChange={e => setEmail(e.target.value)}
                variant='outlined'
                className={classes.input}
                size='small'
                disabled={submitted}
                error={!!errors.email}
              />
              {errors.email
                ? <FormHelperText className={classes.error}>{errors.email}</FormHelperText>
                : <Link
                  className={clsx(classes.actionBtn, submitted && 'disabled')}
                  onClick={() => resendInviteEmailAction(user.email)}
                >Renvoyer l&apos;email d&apos;invitation</Link>
              }
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel id='select-company-label' className={classes.label}>Client</InputLabel>
              {company && <Select
                labelId='select-company-label'
                id='select-company'
                value={company.id}
                onChange={e => setCompany(companies.find(c => c.id === e.target.value))}
                size='small'
                disabled={submitted}
              >
                {companies.map(c => <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>)}
              </Select>}
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel id='select-access-label' className={classes.label}>Niveau d&apos;accès</InputLabel>
              <Select
                labelId='select-access-label'
                id='select-manager'
                value={accessLevel || 25}
                onChange={e => setAccessLevel(e.target.value)}
                size='small'
                disabled={submitted}
              >
                {getRoles().map(r => <MenuItem key={r.accessLevel} value={r.accessLevel}>{r.title}</MenuItem>)}
              </Select>
            </FormControl>
            {availableTeams && teams?.map((team, index) => <Box key={index}>
              <FormControl className={classes.formControl}>
                <InputLabel id='select-team-label' className={classes.label}>Equipe</InputLabel>
                <Select
                  labelId='select-team-label'
                  id='select-team'
                  value={team.id}
                  onChange={e => setTeams(prev => {prev[index] = availableTeams.find(t => t.id === e.target.value); return [...prev]} )}
                  size='small'
                  disabled={submitted}
                >
                  <MenuItem key={team.id} value={team.id}>{team.name}</MenuItem>
                  {availableTeams.map(t => <MenuItem key={t.id} value={t.id}>{t.name}</MenuItem>)}
                </Select>
                <IconButton onClick={() => setTeams(prev => prev.filter(t => t.id !== team.id))} sx={{ ml: 3 }}><Delete /></IconButton>
              </FormControl>
              <FormControl className={classes.formControl}>
                <InputLabel id='select-team-manager' className={classes.label}>Role</InputLabel>
                <Select
                  labelId='select-team-manager'
                  id='select-manager'
                  value={team.manager ? 'manager' : 'member'}
                  onChange={e => setTeams(prev => prev.map(t => t.id === team.id ? { ...t, manager: e.target.value === 'manager' } : t))}
                  size='small'
                  disabled={submitted}
                >
                  <MenuItem value='member'>Membre</MenuItem>
                  <MenuItem value='manager'>Co-animateur</MenuItem>
                </Select>
              </FormControl>
            </Box>)}
            {availableTeams?.length > 0 && <Link
              className={clsx(classes.addConsultantBtn, submitted && 'disabled')}
              onClick={() => setTeams(prev => [...prev, availableTeams[0]])}
              style={{ marginTop: 8, marginLeft: 150 }}
              sx={{ cursor: 'pointer' }}
            >Ajouter à une{!!teams.length && ' autre'} équipe</Link>}
            <FormControl className={classes.formControl}>
              <InputLabel id='profileType-label' className={classes.label}>Profile</InputLabel>
              <span>{user.profileType}</span>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel id='rating-label' className={classes.label}>Rating</InputLabel>
              <StyledRating
                name='customized-color'
                value={talentCard?.rating || 0}
                readOnly
                size='large'
                icon={<Star fontSize='inherit' />}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel id='input-comment-label' className={classes.label}>Commentaire</InputLabel>
              <OutlinedInput
                value={talentCard?.comment || ''}
                variant='outlined'
                className={classes.input}
                size='small'
                disabled
                multiline
              />
            </FormControl>
          </Stack>
        </Grid>
        <Grid item md={4}>
          <SubmitButton disabled={submitted} />
        </Grid>
      </Grid>
    </form>
  )
}

EditUserForm.propTypes = {
  user: PropTypes.object.isRequired,
}

export default EditUserForm
