import { SELECT_COMPANY, SELECT_TEAM } from '../types'

export default (state = {}, action) => {
  switch (action.type) {
    case SELECT_COMPANY: return { ...state, selectedCompanyId: action.payload }
    case SELECT_TEAM: return { ...state, selectedTeamId: action.payload }
    default:
      return state
  }
}
