import React, { useEffect } from 'react'
import { Box, Card, CardContent, Typography } from '@mui/material'
import Logo from '../../assets/logo-boussole.svg'
import LoginForm from './LoginForm'
import { HOME } from '../../_constants/routes'
import { useNavigate } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'


export const LoginPage = () => {

  const profile = useAuth().getProfile()
  const navigate = useNavigate()

  useEffect(() => {
    if (profile.id) navigate(HOME)
  }, [profile])

  return (
    <Box sx={{ display: 'flow-root', height: '100vh', backgroundColor: '#eeeeee' }}>
      <Box component='img' src={Logo} sx={{ position: 'absolute', width: '268px', maxWidth: '70%', m: 1 }} alt='Wake up Logo' />
      <Typography variant='h1' color='primary' textAlign='center' mt='20vh' mb={3} mx={1}>Accès consultant</Typography>
      <Card sx={{ width: '90%', maxWidth: '380px', margin: 'auto', borderRadius: '22px', p: 3 }}>
        <CardContent>
          <LoginForm />
        </CardContent>
      </Card>
    </Box>
  )
}

export default LoginPage
