import React, { useEffect, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import * as ROUTES from './_constants/routes.js'
import RestrictedArea from './components/_library/RestrictedArea'
import LoginPage from './components/auth/LoginPage'
import Notifications from './components/Notifications'
import LoadingBoussole from './components/_library/LoadingBoussole'
import { COMPANY_COLLECTION, ROLE_CONSULTANT, TEAM_COLLECTION, USER_COLLECTION } from './_constants/globals'
import { listenerRefs } from './firebase'
import useAuth from './hooks/useAuth'
import useFirestore from './hooks/useFirestore'
import { mapValues } from 'lodash'
import CompanyPage from './components/company/CompanyPage'
import NewCompanyPage from './components/company/NewCompanyPage'
import EditCompanyPage from './components/company/EditCompanyPage'
import TeamPage from './components/team/TeamPage'
import NewTeamPage from './components/team/NewTeamPage'
import EditTeamPage from './components/team/EditTeamPage'
import UserPage from './components/user/UserPage'
import NewUserPage from './components/user/NewUserPage'
import EditUserPage from './components/user/EditUserPage'
import CampfirePage from './components/campfires/CampfirePage'
import WriteCampfirePage from './components/campfires/WriteCampfirePage'


const App = () => {
  
  const profile = useAuth().getProfile()
  const auth = useAuth()
  const companyHook = useFirestore(COMPANY_COLLECTION)
  const teamHook = useFirestore(TEAM_COLLECTION)
  const userHook = useFirestore(USER_COLLECTION)
  const consultantHook = useFirestore(USER_COLLECTION, { storeAs: 'consultants' })
  const consultantCompanyHook = useFirestore(COMPANY_COLLECTION, { storeAs: 'consultantCompany' })
  
  useEffect(() => {
    auth.listenProfile()
    return () => {
      mapValues(listenerRefs, ({ unsubscribe }) => unsubscribe())
    }
  }, [])
  
  useEffect(() => {
    if (profile.id) {
      companyHook.listen({
        where: [['consultantUserRef', '==', userHook.getDocRef(profile.id)]],
        orderBy: [['name', 'asc']],
      })
      teamHook.listen({
        where: [['consultantUserRef', '==', userHook.getDocRef(profile.id)]],
        orderBy: [['name', 'asc']],
      })
      userHook.listen({
        where: [['consultantUserRef', '==', userHook.getDocRef(profile.id)]],
        orderBy: [['_createdAtTime', 'asc']],
        storeAs: 'contactUsers',
      })
      consultantHook.listen({
        where: [
          ['companyRef', '==', profile.companyRef],
          ['roles', 'array-contains', ROLE_CONSULTANT],
        ],
        orderBy: [['_createdAtTime', 'asc']],
      })
      consultantCompanyHook.listenRef(profile.companyRef)
      return () => {
        companyHook.unsubscribe()
        teamHook.unsubscribe()
        userHook.unsubscribe()
        consultantHook.unsubscribe()
      }
    }
  }, [profile?.id])
  
  // useListener(listenCompanyDocAction, [profile], !!profile.companyRef)
  
  useEffect(() => {
    console.log('Environnement: ' + process.env.NODE_ENV + ' ' + process.env.REACT_APP_FIREBASE_PROJECT_ID)
  }, [])
  
  return (
    <Suspense fallback={<LoadingBoussole />} >
      <Routes>
        <Route path={ROUTES.LOGIN} element={<LoginPage />} />
        <Route path={ROUTES.CLIENTS} element={<RestrictedArea authenticated consultant><CompanyPage /></RestrictedArea>} />
        <Route path={ROUTES.NEW_COMPANY} element={<RestrictedArea authenticated consultant><NewCompanyPage /></RestrictedArea>} />
        <Route path={ROUTES.EDIT_COMPANY} element={<RestrictedArea authenticated consultant><EditCompanyPage /></RestrictedArea>} />
        <Route path={ROUTES.TEAMS} element={<RestrictedArea authenticated consultant><TeamPage /></RestrictedArea>} />
        <Route path={ROUTES.NEW_TEAM} element={<RestrictedArea authenticated consultant><NewTeamPage /></RestrictedArea>} />
        <Route path={ROUTES.EDIT_TEAM} element={<RestrictedArea authenticated consultant><EditTeamPage /></RestrictedArea>} />
        <Route path={ROUTES.USERS} element={<RestrictedArea authenticated consultant><UserPage /></RestrictedArea>} />
        <Route path={ROUTES.NEW_USER} element={<RestrictedArea authenticated consultant><NewUserPage /></RestrictedArea>} />
        <Route path={ROUTES.EDIT_USER} element={<RestrictedArea authenticated consultant><EditUserPage /></RestrictedArea>} />
        
        <Route path={ROUTES.CAMPFIRES} element={<RestrictedArea authenticated consultant><CampfirePage /></RestrictedArea>} />
        <Route path={ROUTES.EDIT_CAMPFIRE} element={<RestrictedArea authenticated consultant><WriteCampfirePage /></RestrictedArea>} />
        <Route path='*' element={<Navigate replace to='clients' />} />
      </Routes>
      <Notifications />
    </Suspense>
  )
}

export default App
