import { RESET_NOTIFICATIONS, DELETE_NOTIFICATION, AUTH_ERROR, SUCCESS, ERROR, WARNING, INFO, LOADING,
  RESET_PASSWORD_EMAIL_SUCCESS, RESET_PASSWORD_EMAIL_ERROR } from '../types'

// eslint-disable-next-line default-param-last
export default (state = {}, action) => {
  switch (action.type) {
    case RESET_NOTIFICATIONS:
      return {}
    case DELETE_NOTIFICATION:
      return { ...state, [action.payload]: null }
    case AUTH_ERROR:
      return { ...state, error: { severity: 'error', message: 'error.login' } }
    case SUCCESS:
      return { ...state, misc: { severity: 'success', message: action.payload } }
    case ERROR:
      return { ...state, misc: { severity: 'error', message: action.payload } }
    case WARNING:
      return { ...state, misc: { severity: 'warning', message: action.payload } }
    case INFO:
      return { ...state, misc: { severity: 'info', message: action.payload } }
    case LOADING:
      return { ...state, misc: { severity: 'loading', message: action.payload } }
  
    case RESET_PASSWORD_EMAIL_SUCCESS:
      return { ...state, error: { severity: 'success', message: 'login.emailResetSend' } }
    case RESET_PASSWORD_EMAIL_ERROR:
      return { ...state, error: { severity: 'error', message: 'login.error.invalid-email' } }

    default:
      return state
  }
}
