import React, { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Box, Button, Typography } from '@mui/material'
import MUIDataTable from 'mui-datatables'
import DeleteButton from '../_library/DeleteButton'
import { format } from 'date-fns'
import { Edit } from '@mui/icons-material'
import { TEAM_COLLECTION, USER_COLLECTION } from '../../_constants/globals'
import { makeStyles } from '@mui/styles'
import LoadingBoussole from '../_library/LoadingBoussole'
import TableToolbar from '../company/TableToolbar'
import CounterButton from '../_library/CounterButton'
import { EDIT_TEAM, NEW_TEAM, USERS } from '../../_constants/routes'
import { Link, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { selectTeamIdAction } from '../../store/actions/dataActions'
import useFirestore from '../../hooks/useFirestore'


const useStyles = makeStyles(theme => {
  const HEADER_RADIUS = 40
  return {
    root: {
      color: 'black',
      backgroundColor: theme.palette.background.default,
      '& .MuiTable-root': {
        borderCollapse: 'separate',
      },
      '& .MuiTableCell-root': {
        padding: theme.spacing(1),
      },
      '& th.MuiTableCell-head': {
        backgroundColor: theme.palette.grey[150],
      },
      '& th.MuiTableCell-head:first-child': {
        borderTopLeftRadius: HEADER_RADIUS,
        borderBottomLeftRadius: HEADER_RADIUS,
      },
      '& th.MuiTableCell-head:last-child': {
        borderTopRightRadius: HEADER_RADIUS,
        borderBottomRightRadius: HEADER_RADIUS,
      },
    },
  }
})

const TeamTable = ({ company }) => {
  
  const classes = useStyles()
  const userHook = useFirestore(USER_COLLECTION)
  const users = userHook.getDocs()
  const teamHooks = useFirestore(TEAM_COLLECTION)
  const teams = teamHooks.getDocs().filter(t => !company || t.companyRef.id === company.id)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  
  const [selectedTeams, setSelectedTeams] = useState([])
  
  const data = useMemo(() =>
    teams?.map(c =>
      [c, c.name, c.company?.name, c._createdAtTime, c.managerRefs || [], c.memberRefs || [], c.id],
    ), [teams])
  
  if (!teams) return <LoadingBoussole />
  else return (
    <MUIDataTable
      className={classes.root}
      data={data}
      columns={[
        { name: 'object', options: { filter: false, sort: false, display: 'excluded', print: false, searchable: false, download: false } },
        {
          name: 'NOM',
          options: {
            customBodyRender: (name, { rowData }) => // eslint-disable-line react/prop-types
              <Box onClick={() => navigate(EDIT_TEAM.replace(':id', rowData[0].id))} sx={{ cursor: 'pointer' }}>
                {name}
              </Box>,
          },
        },
        { name: 'CLIENT' },
        { name: 'CREATION', options: { customBodyRender: date => date ? format(date, 'dd-MM-yyyy') : 'No date' } },
        { name: 'MANAGER', options: { customBodyRender: refs => refs.length && users?.find(u => u.id === refs[0].id)?.firstname || '' } },
        { name: 'USERS',
          options: {
            customBodyRender: (refs, tableMeta) => <CounterButton onClick={() => {
              dispatch(selectTeamIdAction(tableMeta.rowData[0].id))
              navigate(USERS)}
            }>{refs.length}</CounterButton>,
          },
        },
        { name: 'EDIT',
          options: {
            customBodyRender: id => // eslint-disable-line react/display-name
              <>
                <Button onClick={() => navigate(EDIT_TEAM.replace(':id', id))} sx={{ color: 'tertiary.main', minWidth: 40 }}>
                  <Edit />
                </Button>
                <DeleteButton deleteAction={() => teamHooks.deleteDoc(id)} sx={{ color: 'tertiary.main', minWidth: 40 }} />
              </>,
          },
        },
      ]}
      options={{
        responsive: 'simple',
        pagination: false,
        viewColumns: false,
        print: false,
        search: false,
        download: false,
        elevation: 0,
        onRowSelectionChange: (currentRowsSelected, allRowsSelected) => setSelectedTeams(allRowsSelected.map(({ dataIndex }) => teams[dataIndex])),
        selectToolbarPlacement: 'none',
        rowsSelected: selectedTeams.map(c => teams.findIndex(team => team.id === c.id)),
        textLabels: {
          body: {
            noMatch: <Typography>La liste des équipes est vide. <Link to={NEW_TEAM} key='emptyList'>Cliquez ici pour créer la première équipe</Link></Typography>,
          },
        },
      }}
      components={{
        TableToolbar: () => <TableToolbar selectedCompanies={selectedTeams} resetSelect={() => setSelectedTeams([])} collection={TEAM_COLLECTION} />,
      }}
    />
  )
}

TeamTable.propTypes = {
  company: PropTypes.object,
}

export default TeamTable
