import React from 'react'
import PropTypes from 'prop-types'
import { Navigate, useLocation } from 'react-router'
import { LOGIN } from '../../_constants/routes'
import { useSelector } from 'react-redux'
import Loading from './LoadingBoussole'
import useAuth from '../../hooks/useAuth'

const RestrictedArea = ({ children, authenticated, consultant }) => {
  
  const profile = useSelector(state => state.profile)
  const location = useLocation()
  const { signOut } = useAuth()

  if (!profile.isLoaded) return <Loading />

  else if (authenticated && !profile.data.id) return <Navigate to={LOGIN} state={{ from: location }} replace />
  else if (consultant && !profile.data.roles?.includes('consultant')) {
    console.error('NOT A CONSULTANT')
    signOut()
  }

  return children
}

RestrictedArea.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
  authenticated: PropTypes.bool,
  consultant: PropTypes.bool,
}

export default RestrictedArea
