import { styled } from '@mui/styles'
import { Rating } from '@mui/material'
import React from 'react'


const StyledRating = styled(Rating)(({ theme }) => ({
  color: theme.palette.primary.main,
}))

const StyledCustomization = props => <StyledRating {...props} />

export default StyledCustomization
