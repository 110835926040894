import React from 'react'
import PropTypes from 'prop-types'
import { Select, MenuItem, Button, Box } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { CLIENTS, TEAMS, USERS } from '../../_constants/routes'
import { selectCompanyIdAction, selectTeamIdAction } from '../../store/actions/dataActions'


const selectStyle = {
  color: 'tertiary.main',
  cursor: 'pointer',
  backgroundColor: 'white',
  borderRadius: 0,
  fontWeight: 600,
  width: '200px',
  paddingLeft: 2,
  boxShadow: theme => theme.shadows[1],
  clipPath: 'polygon(0 0, 7% 50%, 0 100%, 93% 100%, 100% 50%, 93% 0)',
  '&:first-of-type': {
    clipPath: 'polygon(0 0, 0 100%, 93% 100%, 100% 50%, 93% 0%)',
  },
  '& .MuiSelect-select': {
    padding: 1,
  },
  '&.Mui-disabled': {
    color: 'grey.200',
    backgroundColor: 'grey.150',
    position: 'relative',
    opacity: 1,
    cursor: 'pointer',
  },
  '& > .Mui-disabled': {
    cursor: 'pointer',
  },
  '&:hover': {
    backgroundColor: 'white',
    border: 'none',
  },
  '&.MuiInputBase-root.Mui-focused': {
    backgroundColor: 'white',
  },
  '& .MuiInputBase-input:focus': {
    backgroundColor: 'white',
  },
  '&.MuiInputBase-root:before': {
    border: 'none',
  },
  '&.MuiInputBase-root:after': {
    border: 'none',
  },
  '&.MuiInputBase-root:hover:before': {
    border: 'none',
  },
}

const Breadcrumbs = ({ userId }) => {
  
  const navigate = useNavigate()
  const companies = useSelector(state => state.firestore.companies)
  const teams = useSelector(state => state.firestore.teams)
  const users = useSelector(state => state.firestore.users)
  const companyId = useSelector(state => state.data.selectedCompanyId)
  const teamId = useSelector(state => state.data.selectedTeamId)
  const location = useLocation()
  const dispatch = useDispatch()
  
  const onSelectCompany = id => dispatch(selectCompanyIdAction(id))
  const onSelectTeam = id => dispatch(selectTeamIdAction(id))
  const clearSelects = () => {
    dispatch(selectCompanyIdAction(null))
    dispatch(selectTeamIdAction(null))
    return navigate(CLIENTS)
  }
  
  return (
    <Box sx={{ mb: 4 }}>
      {companies && <Select
        id='company-select-breadcrumb'
        value={companyId || 'all'}
        onChange={e => onSelectCompany(e.target.value)}
        variant='filled'
        sx={selectStyle}
      >
        <MenuItem value='all'><em>Tous les Clients</em></MenuItem>
        {companies.map(c => <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>)}
      </Select>}
      {teams && !!companies?.length && <Select
        id='team-select-breadcrumb'
        value={teamId || 'all'}
        onChange={e => onSelectTeam(e.target.value)}
        variant='filled'
        onClick={() => location.pathname === CLIENTS && navigate(TEAMS)}
        disabled={location.pathname === CLIENTS}
        sx={selectStyle}
      >
        <MenuItem value='all'><em>Toutes les équipes</em></MenuItem>
        {teams.filter(t => !companyId || t.companyRef.id === companyId).map(c => <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>)}
      </Select>}
      {users && !!companies?.length && <Select
        id='user-select-breadcrumb'
        value={userId || 'all'}
        onChange={() => null}
        variant='filled'
        onClick={() => navigate(USERS)}
        disabled={location.pathname !== USERS}
        sx={selectStyle}
      >
        <MenuItem value='all'><em>Tous les utilisateurs</em></MenuItem>
        {users.filter(t => t.teamRefs?.some(ref => ref.id === teamId)).map(c => <MenuItem key={c.id} value={c.id}>{c.firstname}</MenuItem>)}
      </Select>}
      <Button onClick={clearSelects} sx={{
        color: 'secondary.main',
        backgroundColor: 'transparent',
        textTransform: 'none',
        boxShadow: 'none',
        verticalAlign: 'top',
        '&:hover': {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      }}>clear</Button>
    </Box>
  )
}

Breadcrumbs.propTypes = {
  userId: PropTypes.string,
}

export default Breadcrumbs
