import React from 'react'
import { Grid } from '@mui/material'
import BackButton from './BackButton'
import PropTypes from 'prop-types'


const FormContainer = ({ form, children }) => (
  <Grid container sx={{
    backgroundColor: 'white',
    position: 'relative',
    minHeight: 300,
    borderRadius: '22px',
    p: 5,
    boxShadow: '0 0 10px grey.200',
  }}>
    <BackButton sx={{ position: 'absolute', right: 40, top: -20 }} />
    <Grid item md={8} sx={{ borderRight: 'solid 2px', borderColor: 'grey.150' }}>
      {form}
    </Grid>
    <Grid item md={4}>
      {children}
    </Grid>
  </Grid>
)

FormContainer.propTypes = {
  form: PropTypes.element.isRequired,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
}

export default FormContainer
