import React from 'react'
import { Button } from '@mui/material'
import { styled } from '@mui/styles'
import { useNavigate } from 'react-router-dom'


const BackButton = styled(Button)(({ theme }) => ({
  color: theme.palette.grey[100],
  clipPath: `polygon(15% 0, 0 50%, 15% 100%, 100% 100%, 100% 0)`,
  fontWeight: 800,
  borderRadius: 7,
  textTransform: 'initial',
  backgroundColor: theme.palette.secondary.main,
  padding: theme.spacing(1, 3),
  '&:disabled': {
    backgroundColor: theme.palette.grey[200],
    color: 'white',
  },
  '&:hover': {
    backgroundColor: theme.palette.secondary.dark,
  },
}))

const StyledCustomization = props => {
  const navigate = useNavigate()
  return <BackButton variant='contained' onClick={() => navigate(-1)} {...props} >back</BackButton>
}

export default StyledCustomization
