import React from 'react'
import Page from '../_library/Page'
import NewTeamForm from './NewTeamForm'
import FormContainer from '../_library/FormContainer'


const NewTeamPage = () => (
  <Page title='Nouvelle équipe'>
    <FormContainer form={<NewTeamForm />} />
  </Page>
)

export default NewTeamPage
