import React, { useEffect, useState } from 'react'
import { FormControl, FormHelperText, Grid, InputLabel, MenuItem, OutlinedInput, Select, Stack, TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { DatePicker } from '@mui/x-date-pickers'
import SubmitButton from '../_library/SubmitButton'
import { COMPANY_COLLECTION, USER_COLLECTION } from '../../_constants/globals'
import PropTypes from 'prop-types'
import useFirestore from '../../hooks/useFirestore'
import { increment } from 'firebase/firestore'
import useAuth from '../../hooks/useAuth'
import { compact } from 'lodash'
import { createNotification } from '../../store/actions/notificationActions'
import { useDispatch } from 'react-redux'
import { SUCCESS } from '../../store/types'
import { useTranslation } from 'react-i18next'


const useStyles = makeStyles(() => ({
  label: {
    position: 'static',
    transform: 'none',
    display: 'inline-block',
    width: 150,
    fontWeight: 600,
    lineHeight: '40px',
    verticalAlign: 'bottom',
  },
  input: {
    display: 'inline-block',
    width: 200,
  },
  error: {
    marginLeft: 150,
  },
  actionBtn: {
    textDecoration: 'underline',
    cursor: 'pointer',
    width: 'fit-content',
    opacity: .5,
  },
}))

const EditCompanyForm = ({ company }) => {
  
  const classes = useStyles()
  const userHook = useFirestore(USER_COLLECTION)
  const contactUsers = userHook.getDocs()
  const consultantHook = useFirestore(USER_COLLECTION, { storeAs: 'consultants' })
  const dbConsultants = consultantHook.getDocs()
  const companyHook = useFirestore(COMPANY_COLLECTION)
  const profile = useAuth().getProfile()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  
  const [name, setName] = useState(null)
  const [createdAtTime, setCreatedAtTime] = useState(new Date())
  const [licences, setLicences] = useState(0)
  const [consultant, setConsultant] = useState(null)
  const [contact, setContact] = useState(null)
  const [submitted, setSubmitted] = useState(false)
  const [errors, setErrors] = useState({})
  
  useEffect(() => {
    setName(company.name)
    setCreatedAtTime(company.createdAtTime || company._createdAtTime || new Date())
    setLicences(company.licences)
    setConsultant(dbConsultants.find(u => company.consultantUserRef.id === u.id))
    setContact(contactUsers?.filter(u => u.companyRef.id === company.id)[0])
  }, [company, contactUsers])
  
  
  const handleSubmit = e => {
    e.preventDefault()
    if (!name) setErrors(val => ({ ...val, name: 'Name field must not be empty' }))
    if (name) {
      setSubmitted(true)
      companyHook.batchSetAction(compact([
        licences !== company.licences && { id: profile.companyRef.id, externalLicences: increment(company.licences - Number(licences)) },
        {
          id: company.id,
          name,
          createdAtTime,
          consultantUserRef: consultantHook.getDocRef(consultant.id),
          consultantCompanyRef: companyHook.getDocRef(consultant.companyRef.id),
          licences: Number(licences),
        },
      ]))
        .then(() => dispatch(createNotification(SUCCESS, t('notifications.companies.update.success'))))
        .finally(() => setSubmitted(false))
    }
  }
  
  return (
    <form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item md={8}>
          <Stack spacing={3}>
            <FormControl sx={{ display: 'block' }}>
              <InputLabel id='input-name-label' className={classes.label}>Nom</InputLabel>
              <OutlinedInput
                value={name || ''}
                onChange={e => setName(e.target.value)}
                variant='outlined'
                className={classes.input}
                size='small'
                disabled={submitted}
                error={!!errors.name}
              />
              {!!errors.name && <FormHelperText className={classes.error}>{errors.name}</FormHelperText>}
            </FormControl>
            <FormControl sx={{ display: 'block' }}>
              <InputLabel id='datepicker-createdat-label' className={classes.label}>Date de création</InputLabel>
              <DatePicker
                inputFormat='dd/MM/yyyy'
                value={createdAtTime}
                onChange={setCreatedAtTime}
                renderInput={params => <TextField {...params} className={classes.input} size='small' />}
                size='small'
                disabled={submitted}
                error={!!errors.createdAt}
              />
            </FormControl>
            <FormControl sx={{ display: 'block' }}>
              <InputLabel id='input-licences-label' className={classes.label}>Licences</InputLabel>
              <OutlinedInput
                value={licences}
                onChange={e => e.target.value >= 0 && setLicences(e.target.value)}
                variant='outlined'
                className={classes.input}
                size='small'
                disabled={submitted}
                error={!!errors.licences}
                type='number'
              />
            </FormControl>
            {dbConsultants && consultant && <FormControl key={consultant.id || 'void'} sx={{ display: 'block' }}>
              <InputLabel id='select-consultant-label' className={classes.label}>Consultant</InputLabel>
              <Select
                labelId='select-consultant-label'
                id='select-consultant'
                value={consultant.id}
                onChange={e => setConsultant(dbConsultants.find(u => u.id === e.target.value))}
                size='small'
                disabled={submitted}
              >
                {dbConsultants.map(user =>
                  <MenuItem key={user.id} value={user.id}>{user.firstname}</MenuItem>,
                )}
              </Select>
            </FormControl>}
            {contact && <>
              <FormControl sx={{ display: 'block' }}>
                <InputLabel id='input-contact-label' className={classes.label}>Contact</InputLabel>
                <Select
                  labelId='select-contact-label'
                  id='select-contact'
                  value={contact.id}
                  size='small'
                  disabled
                >
                  <MenuItem key={contact.id} value={contact.id}>{contact.firstname}</MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ display: 'block', mt: 2 }}>
                <InputLabel id='input-name-label' className={classes.label}></InputLabel>
                <OutlinedInput
                  value={contact.email || ''}
                  variant='outlined'
                  className={classes.input}
                  size='small'
                  disabled
                />
              </FormControl>
            </>}
          </Stack>
        </Grid>
        <Grid item md={4}>
          <SubmitButton disabled={submitted || !company.consultantCompanyRef} />
        </Grid>
      </Grid>
    </form>
  )
}

EditCompanyForm.propTypes = {
  company: PropTypes.object.isRequired,
}

export default EditCompanyForm
