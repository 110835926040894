export const SUCCESS = 'SUCCESS'
export const ERROR = 'ERROR'
export const WARNING = 'WARNING'
export const INFO = 'INFO'
export const LOADING = 'LOADING'

export const LISTEN_PROFILE = 'LISTEN_PROFILE'
export const AUTH_ERROR = 'AUTH_ERROR'
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS'
export const CAPTCHA_VERIFIED = 'CAPTCHA_VERIFIED'
export const CONFIRMATION_RESULT = 'CONFIRMATION_RESULT'

export const RESET_NOTIFICATIONS = 'RESET_NOTIFICATIONS'
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION'

export const LISTEN_COLLECTION = 'LISTEN_COLLECTION'
export const LISTEN_COMPANY_DOC = 'LISTEN_COMPANY_DOC'

export const SELECT_COMPANY = 'SELECT_COMPANY'
export const SELECT_TEAM = 'SELECT_TEAM'

export const RESET_PASSWORD_EMAIL_SUCCESS = 'RESET_PASSWORD_EMAIL_SUCCESS'
export const RESET_PASSWORD_EMAIL_ERROR = 'RESET_PASSWORD_EMAIL_ERROR'
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS'
export const PASSWORD_RESET_ERROR = 'PASSWORD_RESET_ERROR'

export const UPLOAD_PROGRESS = 'UPLOAD_PROGRESS'
export const UPLOAD_ERROR = 'UPLOAD_ERROR'
export const UPLOAD_COMPLETE = 'UPLOAD_COMPLETE'
