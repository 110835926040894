import React, { useEffect, useMemo, useState } from 'react'
import { Box } from '@mui/material'
import { COMPANY_COLLECTION } from '../../_constants/globals'
import useFirestore from '../../hooks/useFirestore'
import useAuth from '../../hooks/useAuth'


const InvitationDisplay = () => {
  
  const profile = useAuth().getProfile()
  const companyHooks = useFirestore(COMPANY_COLLECTION)
  
  const [company, setCompany] = useState(null)
  
  useEffect(() => {
    companyHooks.fetchRef(profile.companyRef).then(setCompany)
  }, [profile.companyRef])

  const text = useMemo(() => {
    if (company?.externalLicences > 1) return 'licences restantes'
    else return 'licence restante'
  }, [company])

  return company && (
    <Box sx={{
      position: 'absolute',
      top: 80,
      right: 0,
      p: 1,
      bgcolor: 'grey.100',
      color: 'primary.main',
      float: { md: 'right' },
      width: { sm: '100%', md: 'initial' },
      textAlign: { sm: 'center', md: 'initial' },
    }}>
      <b>{company.externalLicences || 0}</b>&nbsp;{text}
    </Box>
  )
}

export default InvitationDisplay
