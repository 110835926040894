import React, { useMemo } from 'react'
import Page from '../_library/Page'
import TeamTable from './TeamTable'
import { useSelector } from 'react-redux'
import Breadcrumbs from '../_library/Breadcrumbs'


const TeamPage = () => {

  const companies = useSelector(state => state.firestore.companies)
  const selectedCompanyId = useSelector(state => state.data.selectedCompanyId)

  const selectedCompany = useMemo(() => companies?.find(c => c.id === selectedCompanyId), [companies, selectedCompanyId])

  return (
    <Page title='Tableau de bord - Equipes'>
      <Breadcrumbs />
      <TeamTable company={selectedCompany} />
    </Page>
  )
}

export default TeamPage
