import React, { useEffect, useMemo, useState } from 'react'
import { FormControl, FormHelperText, Grid, InputLabel, MenuItem, OutlinedInput, Select, Stack, TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import SubmitButton from '../_library/SubmitButton'
import { COMPANY_COLLECTION, TEAM_COLLECTION, USER_COLLECTION } from '../../_constants/globals'
import PropTypes from 'prop-types'
import useFirestore from '../../hooks/useFirestore'
import { arrayUnion } from 'firebase/firestore'
import { createNotification } from '../../store/actions/notificationActions'
import { ERROR, SUCCESS } from '../../store/types'
import { useDispatch } from 'react-redux'
import { pickBy } from 'lodash'


const labelStyle = {
  position: 'static',
  transform: 'none',
  display: 'inline-block',
  width: 150,
  fontWeight: 600,
  lineHeight: '40px',
  verticalAlign: 'bottom',
  color: 'primary.dark',
}

const EditTeamForm = ({ team }) => {
  
  const companyHook = useFirestore(COMPANY_COLLECTION)
  const companies = companyHook.getDocs()
  const teamHook = useFirestore(TEAM_COLLECTION)
  const userHook = useFirestore(USER_COLLECTION)
  const users = userHook.getDocs()
  const dispatch = useDispatch()
  
  const [name, setName] = useState(null)
  const [company, setCompany] = useState(null)
  const [manager, setManager] = useState(null)
  const [campFire1, setCampFire1] = useState(null)
  const [campFire2, setCampFire2] = useState(null)
  const [submitted, setSubmitted] = useState(false)
  const [errors, setErrors] = useState({})
  
  const filteredUsers = useMemo(() => users?.filter(u => u.companyRef?.id === company?.id), [users, company])
  
  useEffect(() => {
    setName(team.name)
    !company && setCompany(companies?.find(c => c.id === team.companyRef.id))
    team.managerRefs?.length && setManager(filteredUsers?.find(c => c.id === team.managerRefs[0].id))
    setCampFire1(team.safeCocoonDate)
    setCampFire2(team.teamBuildingDate)
  }, [team, companies, filteredUsers])
  
  const handleSubmit = e => {
    e.preventDefault()
    setErrors({})
    if (!name) setErrors(val => ({ ...val, name: 'Name field must not be empty' }))
    if (name) {
      setSubmitted(true)
      const data = {
        name,
        companyRef: companyHook.getDocRef(company.id),
        managerRefs: manager ? [userHook.getDocRef(manager.id)] : null,
        safeCocoonDate: campFire1,
        teamBuildingDate: campFire2,
      }
      if (manager) data.memberRefs = arrayUnion(userHook.getDocRef(manager.id))
      teamHook.updateDoc(team.id, pickBy(data), true)
        .then(() => dispatch(createNotification(SUCCESS, 'notifications.teams.update.success')))
        .catch(() => dispatch(createNotification(ERROR, 'notifications.update.error')))
        .finally(() => setSubmitted(false))
    }
  }
  
  return (
    <form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item md={8}>
          <Stack spacing={3}>
            <FormControl error={!!errors.name} sx={{ display: 'block' }}>
              <InputLabel id='input-name-label' sx={labelStyle}>Nom</InputLabel>
              <OutlinedInput
                value={name || ''}
                onChange={e => setName(e.target.value)}
                variant='outlined'
                size='small'
                sx={{ display: 'inline-block', width: 200 }}
                disabled={submitted}
              />
              {!!errors.name && <FormHelperText sx={{ ml: '150px' }}>{errors.name}</FormHelperText>}
            </FormControl>
            <FormControl sx={{ display: 'block' }}>
              <InputLabel id='select-company-label' sx={labelStyle}>Client</InputLabel>
              {company && <Select
                labelId='select-company-label'
                id='select-company'
                value={company.id}
                onChange={e => setCompany(companies.find(c => c.id === e.target.value))}
                size='small'
                disabled={submitted}
              >
                {companies.map(c => <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>)}
              </Select>}
            </FormControl>
            <FormControl error={!!errors.manager} sx={{ display: 'block' }}>
              <InputLabel id='select-manager-label' sx={labelStyle}>Manager</InputLabel>
              {filteredUsers && <Select
                labelId='select-manager-label'
                id='select-manager'
                value={manager?.id || ''}
                onChange={e => setManager(filteredUsers.find(u => u.id === e.target.value) || null)}
                size='small'
                disabled={submitted}
              >
                <MenuItem value=''><em>Sans manager</em></MenuItem>
                {filteredUsers.map(u => <MenuItem key={u.id} value={u.id}>{u.firstname}</MenuItem>)}
              </Select>}
              {!!errors.manager && <FormHelperText sx={{ ml: '150px' }}>{errors.manager}</FormHelperText>}
            </FormControl>
            <FormControl sx={{ display: 'block' }}>
              <InputLabel id='datepicker-campfire1-label' sx={labelStyle}>Feu de camp 1</InputLabel>
              <DatePicker
                inputFormat='dd/MM/yyyy'
                value={campFire1}
                onChange={setCampFire1}
                renderInput={params => <TextField {...params} size='small' sx={{ display: 'inline-block', width: 200 }} />}
                size='small'
                disabled={submitted}
                error={!!errors.campFire1}
              />
            </FormControl>
            <FormControl sx={{ display: 'block' }}>
              <InputLabel id='datepicker-campfire1-label' sx={labelStyle}>Feu de camp 2</InputLabel>
              <DatePicker
                inputFormat='dd/MM/yyyy'
                value={campFire2}
                onChange={setCampFire2}
                renderInput={params => <TextField {...params} size='small' sx={{ display: 'inline-block', width: 200 }} />}
                size='small'
                disabled={submitted}
                error={!!errors.campFire2}
              />
            </FormControl>
          </Stack>
        </Grid>
        <Grid item md={4}>
          <SubmitButton disabled={submitted} />
        </Grid>
      </Grid>
    </form>
  )
}

EditTeamForm.propTypes = {
  team: PropTypes.object.isRequired,
}

export default EditTeamForm
