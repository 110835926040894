import { useMemo, useRef } from 'react'
import { deSerialize, getQuery } from '../_helpers/firestoreHelper'
import { collection, doc, onSnapshot, serverTimestamp, setDoc as setDocFb, writeBatch, updateDoc as updateDocFb, addDoc as addDocFb, deleteDoc as deleteDocFb, getDocs as getDocsFb, getDoc } from 'firebase/firestore'
import { USER_COLLECTION } from '../_constants/globals'
import { db, listenerRefs } from '../firebase'
import { useDispatch, useSelector } from 'react-redux'
import { omit, pickBy, isNil } from 'lodash'
import { actions } from '../store/slices/firestoreSlice'
import useAuth from './useAuth'


const useFirestore = (path, options) => {

  const lastDocRef = useRef()
  const profile = useAuth().getProfile()
  const dispatch = useDispatch()
  const serializedDocs = useSelector(state => state.firestore[options?.storeAs || path])
  const deserializedDocs = useMemo(() => serializedDocs?.map(deSerialize), [serializedDocs])

  const listen = listenOptions => {
    const query = getQuery(path, listenOptions)
    dispatch(actions.loading())
    const listener = onSnapshot(query, {}, snap => {
      if (snap.empty) return dispatch(actions?.success(path, []))
      else {
        const data = snap.docs.map(doc => ({ id: doc.id, ...omit(doc.data(), listenOptions?.omit || []) }))
        dispatch(actions?.success(options?.storeAs || path, data))
        if (listenOptions?.lazyLoad) lastDocRef.current = snap.docs.pop()
      }
    })
    listenerRefs[options?.listenerName || path] = { unsubscribe: listener }
  }
  
  const listenRef = (ref, listenOptions) => {
    dispatch(actions.loading())
    const listener = onSnapshot(ref, snap => {
      if (snap.empty) return dispatch(actions?.success(path, []))
      else {
        const data = [{ id: snap.id, ...omit(snap.data(), listenOptions?.omit || []) }]
        dispatch(actions?.success(options?.storeAs || path, data))
      }
    })
    listenerRefs[options?.listenerName || path] = { unsubscribe: listener }
  }

  const fetch = options =>
    getDocsFb(getQuery(path, options)).then(snap =>
      snap.docs.map(doc => ({ id: doc.id, ...doc.data() })),
    )
  
  const fetchRef = ref => getDoc(ref).then(doc => ({ id: doc.id, ...doc.data() }))

  const getDocs = () => deserializedDocs

  const getDocRef = id => id ? doc(db, path, id) : doc(collection(db, path))

  const addDoc = data =>
    addDocFb(collection(db, path), {
      ...data,
      _createdAtTime: serverTimestamp(),
      _createdByRef: doc(db, USER_COLLECTION, profile.id),
    })
      .then(res => console.info(`${path} created with id: ${res.id}`))
      .catch(e => console.log('Error creating document', e))

  const updateDoc = (id, data) =>
    updateDocFb(doc(db, path, id), {
      ...data,
      _updatedAtTime: serverTimestamp(),
      _updatedByRef: doc(db, USER_COLLECTION, profile.id),
    })
      .then(() => console.info(`${path} updated, id: ${id}`))
      .catch(e => console.error(`Error updating document with id: ${id}`, e))

  const setDoc = async (id, data) => {
    const ref = getDocRef(id)
    await setDocFb(ref, pickBy({
      ...data,
      _createdAtTime: !id && serverTimestamp(),
      _createdByRef: !id && doc(db, USER_COLLECTION, profile.id),
      _updatedAtTime: !!id && serverTimestamp(),
      _updatedByRef: !!id && doc(db, USER_COLLECTION, profile.id),
    }), { merge: true })
    console.info(`${path} set, id: ${ref.id}`)
    return ref
  }

  const deleteDoc = id =>
    deleteDocFb(doc(db, path, id))
      .then(() => console.info(`${path} deleted, id: ${id}`))
      .catch(e => console.error(`Error deleting document with id: ${id}`, e))

  /**
   * @param {[string]} ids
   */
  const batchDeleteDocAction = ids => {
    const batch = writeBatch(db)
    ids.forEach(id => batch.delete(doc(db, path, id)))
    return batch.commit()
  }

  /**
   * @param {object[]} data
   * @returns {Promise<void>}
   */
  const batchSetAction = data => {
    const batch = writeBatch(db)
    data.forEach(document => batch.set(document.id ? doc(db, path, document.id.toString()) : doc(collection(db, path)), pickBy({
      ...document,
      id: null,
      _createdAtTime: !document?.id && serverTimestamp(),
      _createdByRef: !document?.id && doc(db, USER_COLLECTION, profile.id),
      _updatedAtTime: document?.id && serverTimestamp(),
      _updatedByRef: document?.id && doc(db, USER_COLLECTION, profile.id),
    }, val => !isNil(val)), { merge: true }))
    return batch.commit()
  }

  const getBatch = () => writeBatch(db)

  const unsubscribe = listenerName => listenerRefs[listenerName || options?.storeAs || path]?.unsubscribe()

  return { listen, listenRef, fetch, fetchRef, getDocs, getDocRef, addDoc, updateDoc, setDoc, deleteDoc, batchDeleteDocAction, batchSetAction, getBatch, unsubscribe }
}

export default useFirestore
