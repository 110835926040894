import React from 'react'
import TopBar from './TopBar'
import { Box, Typography } from '@mui/material'
import PropTypes from 'prop-types'


const Page = ({ title, children }) => (
  <div>
    <TopBar />
    <Box sx={{ maxWidth: 1200, m: 'auto', py: 5, px: 1 }}>
      <Typography variant='h1' sx={{ mb: 3 }}>{title}</Typography>
      {children}
    </Box>
  </div>
)

Page.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
}

export default Page
