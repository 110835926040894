import React, { useMemo } from 'react'
import TopBar from './TopBar'
import { Box, Tab, Tabs } from '@mui/material'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { CAMPFIRES, CLIENTS } from '../../_constants/routes'
import { useLocation } from 'react-router'


const TablePage = ({ children }) => {
  
  const navigate = useNavigate()
  const { pathname } = useLocation()
  
  const selectedTab = useMemo(() => pathname === CAMPFIRES ? 1 : 0, [pathname])
  
  return (
    <div>
      <TopBar />
      <Box sx={{
        maxWidth: 1200,
        margin: 'auto',
        py: 5,
        px: 1,
      }}>
        <Tabs value={selectedTab} sx={{ mb: 4 }}>
          <Tab label='Clients' id='tab-0' value={0} onClick={() => navigate(CLIENTS)} />
          <Tab label='Feux de camp' id='tab-1' value={1} onClick={() => navigate(CAMPFIRES)} />
        </Tabs>
        {children}
      </Box>
    </div>
  )
}

TablePage.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
}

export default TablePage
