export const getTeamProfileType = teamMembers => {
  if (!teamMembers || !teamMembers[0]) return
  const result = teamMembers
    .reduce(
      (acc, val) => ({ testResults: { X: acc.testResults.X + val.testResults.X, Y: acc.testResults.Y + val.testResults.Y } } ),
      { testResults: { X: 0, Y: 0 } },
    )
  const X = result.testResults.X / teamMembers.length
  const Y = result.testResults.Y / teamMembers.length
  const absX = Math.abs(X)
  const absY = Math.abs(Y)
  let first, second
  if (X > 0) {
    if (Y > 0) {
      first = 'C'
      second = absX > absY ? 'M' : 'A'
    } else {
      first = 'M'
      second = absX > absY ? 'C' : 'F'
    }
  } else {
    if (Y > 0) {
      first = 'A'
      second = absX > absY ? 'F' : 'C'
    } else {
      first = 'F'
      second = absX > absY ? 'A' : 'M'
    }
  }
  const third = teamMembers.filter(el => el.profileType.split('')[2] === 'I').length > teamMembers.length / 2 ? 'I' : 'E'
  return first + second + third
}
