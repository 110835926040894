import React from 'react'
import { Button } from '@mui/material'
import { styled } from '@mui/styles'
import { Check } from '@mui/icons-material'
import PropTypes from 'prop-types'

const SubmitButton = styled(Button)(({ theme }) => ({
  borderRadius: 5,
  color: theme.palette.grey[100],
  backgroundColor: theme.palette.tertiary.main,
  padding: 2,
  paddingRight: theme.spacing(1),
  '&:disabled': {
    backgroundColor: theme.palette.grey[200],
    color: 'white',
  },
  '&:hover': {
    backgroundColor: theme.palette.tertiary.main,
  },
}))

const StyledCustomization = props => (
  <SubmitButton variant='contained' type='submit' {...props}>
    <Check />{props.children || 'Enregistrer'}
  </SubmitButton>
)

StyledCustomization.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
}

export default StyledCustomization
