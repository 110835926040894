import React, { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Typography } from '@mui/material'
import MUIDataTable from 'mui-datatables'
import DeleteButton from '../_library/DeleteButton'
import { format } from 'date-fns'
import { Edit } from '@mui/icons-material'
import { COMPANY_COLLECTION } from '../../_constants/globals'
import LoadingBoussole from '../_library/LoadingBoussole'
import TableToolbar from './TableToolbar'
import CounterButton from '../_library/CounterButton'
import { EDIT_COMPANY, NEW_COMPANY, TEAMS, USERS } from '../../_constants/routes'
import { Link, useNavigate } from 'react-router-dom'
import { selectCompanyIdAction } from '../../store/actions/dataActions'
import useFirestore from '../../hooks/useFirestore'


const HEADER_RADIUS = 40
const CompanyTable = () => {

  const companyHook = useFirestore(COMPANY_COLLECTION)
  const companies = companyHook.getDocs()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [selectedCompanies, setSelectedCompanies] = useState([])

  const data = useMemo(() =>
    companies?.map(c =>
      [c, c.name, c.consultantUser?.firstname, c.createdAtTime, c.counterTeams || 0, c.counterUsers || 0, c.id],
    ), [companies])

  if (!companies) return <LoadingBoussole />
  else return <>
    <MUIDataTable
      data={data}
      columns={[
        { name: 'object', options: { filter: false, sort: false, display: 'excluded', print: false, searchable: false, download: false } },
        { name: 'Nom' },
        { name: 'Consultant(s)' },
        { name: 'Création', options: { customBodyRender: date => date ? format(date, 'dd-MM-yyyy') : 'No date' } },
        { name: 'Teams', options: { customBodyRender: (counter, tableMeta) => <CounterButton onClick={() => {dispatch(selectCompanyIdAction(tableMeta.rowData[0].id)); navigate(TEAMS)}}>{counter}</CounterButton> } },
        { name: 'Users', options: { customBodyRender: (counter, tableMeta) => <CounterButton onClick={() => {dispatch(selectCompanyIdAction(tableMeta.rowData[0].id)); navigate(USERS)}}>{counter}</CounterButton> } },
        { name: 'Edit',
          options: {
            customBodyRender: id => // eslint-disable-line react/display-name
              <>
                <Button
                  onClick={() => navigate(EDIT_COMPANY.replace(':id', id))}
                  sx={{ color: 'tertiary.main', minWidth: 40 }}
                >
                  <Edit />
                </Button>
                <DeleteButton
                  deleteAction={() => companyHook.deleteDoc(id)}
                  sx={{ color: 'tertiary.main', minWidth: 40 }}
                />
              </>,
          },
        },
      ]}
      options={{
        responsive: 'simple',
        pagination: false,
        viewColumns: false,
        print: false,
        search: false,
        download: false,
        elevation: 0,
        onRowSelectionChange: (currentRowsSelected, allRowsSelected) => setSelectedCompanies(allRowsSelected.map(({ dataIndex }) => companies[dataIndex])),
        selectToolbarPlacement: 'none',
        rowsSelected: selectedCompanies.map(c => companies.findIndex(company => company.id === c.id)),
        textLabels: {
          body: {
            noMatch: <Typography>La liste des clients est vide. <Link to={NEW_COMPANY} key='emptyList'>Cliquez ici pour créer votre premier client</Link></Typography>,
          },
        },
      }}
      components={{
        TableToolbar: () => <TableToolbar selectedCompanies={selectedCompanies} resetSelect={() => setSelectedCompanies([])} collection={COMPANY_COLLECTION} />,
      }}
      sx={{
        color: 'black',
        backgroundColor: 'background.default',
        '& .MuiTable-root': {
          borderCollapse: 'separate',
        },
        '& .MuiTableCell-root': {
          p: 1,
        },
        '& th.MuiTableCell-head': {
          backgroundColor: 'grey.150',
        },
        '& th.MuiTableCell-head:first-child': {
          borderTopLeftRadius: HEADER_RADIUS,
          borderBottomLeftRadius: HEADER_RADIUS,
        },
        '& th.MuiTableCell-head:last-child': {
          borderTopRightRadius: HEADER_RADIUS,
          borderBottomRightRadius: HEADER_RADIUS,
        },
      }}
    />
  </>
}

export default CompanyTable
