import React from 'react'
import TopBar from './TopBar'
import { Box, Grid, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import BackButton from './BackButton'


const FormPage = ({ title, form, actions }) => (
  <div>
    <TopBar />
    <Box sx={{ maxWidth: 1200, m: 'auto', py: 5, px: 1 }}>
      <Typography variant='h1' sx={{ mb: 3 }}>{title}</Typography>
      <Grid container sx={{
        backgroundColor: 'white',
        position: 'relative',
        minHeight: 300,
        borderRadius: '22px',
        p: 5,
        boxShadow: theme => `0 0 10px ${theme.palette.grey[200]}`,
      }}>
        <BackButton sx={{ position: 'absolute', right: 40, top: -20 }} />
        <Grid item md={8} sx={{ borderRight: 'solid 3px', borderColor: 'grey.150' }}>
          <Box sx={{ maxWidth: '600px' }}>
            {form}
          </Box>
        </Grid>
        <Grid item md={4}>
          <Box sx={{ maxWidth: '300px', float: 'right', width: '-webkit-fill-available' }}>
            {actions}
          </Box>
        </Grid>
      </Grid>
    </Box>
  </div>
)

FormPage.propTypes = {
  title: PropTypes.string.isRequired,
  form: PropTypes.element.isRequired,
  actions: PropTypes.element.isRequired,
}

export default FormPage
