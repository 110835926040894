import React from 'react'
import { Button } from '@mui/material'
import PropTypes from 'prop-types'


const CTAButton = props => <Button variant='contained' {...props} sx={{
  padding: '.5em 1.5em',
  mx: 'auto',
  borderRadius: '2rem',
  fontSize: { xs: '1rem', lg: '1.3rem' },
  color: 'grey.100',
  fontWeight: 800,
  textTransform: 'initial',
  backgroundColor: 'secondary.main',
  justifyContent: 'center',
  '&:disabled': {
    backgroundColor: 'grey.200',
    color: 'white',
  },
  '&:hover': {
    backgroundColor: 'secondary.main',
  },
  ...props.sx,
}} />

CTAButton.propTypes = {
  sx: PropTypes.object,
}

export default CTAButton
