import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, ClickAwayListener, Tooltip, CircularProgress, IconButton } from '@mui/material'
import { Delete } from '@mui/icons-material'


const DeleteButton = ({ deleteAction, ...props }) => {

  const [activated, setActivated] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const action = () => {
    setSubmitted(true)
    deleteAction()
  }

  return activated
    ? <ClickAwayListener onClickAway={() => setActivated(false)}>
      {submitted
        ? <CircularProgress color='inherit' size='1.5rem' />
        : <Button style={{ color: 'red', margin: 5 }} onClick={action}>Supprimer ?</Button>
      }
    </ClickAwayListener>
    : <Tooltip title='Supprimer'>
      <IconButton style={{ minWidth: 40 }} onClick={() => setActivated(true)} {...props}>
        <Delete />
      </IconButton>
    </Tooltip>
}

DeleteButton.propTypes = {
  deleteAction: PropTypes.func.isRequired,
}

export default DeleteButton
