import { onAuthStateChanged, signInWithEmailAndPassword, signOut as apiSignOut } from 'firebase/auth'
import { addDoc, collection, doc, onSnapshot, serverTimestamp } from 'firebase/firestore'
import { EMAIL_COLLECTION, EMAIL_RE_INVITE, PROFILE_STORAGE_REF, USER_COLLECTION } from '../_constants/globals'
import { auth, db, functions, listenerRefs, storage } from '../firebase'
import { useDispatch, useSelector } from 'react-redux'
import { actions } from '../store/slices/profileSlice'
import { mapValues } from 'lodash'
import { resetLocalStorage } from '../_helpers/localStorageHelper'
import { AUTH_ERROR, ERROR, RESET_PASSWORD_EMAIL_ERROR, RESET_PASSWORD_EMAIL_SUCCESS, SUCCESS, UPLOAD_COMPLETE, UPLOAD_ERROR, UPLOAD_PROGRESS } from '../store/types'
import { httpsCallable } from 'firebase/functions'
import { ref, uploadBytesResumable } from 'firebase/storage'
import { useMemo } from 'react'
import { deSerialize } from '../_helpers/firestoreHelper'


const useAuth = () => {
  const dispatch = useDispatch()
  const serializedProfile = useSelector(state => state.profile.data)
  
  const deserializedProfile = useMemo(() => deSerialize(serializedProfile), [serializedProfile])
  
  const listenProfile = () =>
    onAuthStateChanged(auth, authUser => {
      if (authUser) {
        const collectionListener = onSnapshot(doc(db, USER_COLLECTION, authUser.uid), async dbUser => {
          const profile = {
            id: dbUser.id,
            ...dbUser.data(),
            isAnonymous: auth.currentUser.isAnonymous,
            emailVerified: auth.currentUser.emailVerified,
            isLoaded: true,
            token: await auth.currentUser.getIdTokenResult(),
            isEmpty: false,
          }
          console.info('profile', profile)
          return dispatch(actions.success(profile))
        })
        listenerRefs.auth = { unsubscribe: collectionListener }
      }
      else dispatch(actions.success({ isEmpty: true }))
    })
  
  const getProfile = () => deserializedProfile
  
  const signIn = ({ email, password }) =>
    signInWithEmailAndPassword(auth, email, password)
      .catch(err => dispatch({
        type: AUTH_ERROR,
        payload: err,
      }))
  
  const signOut = () => {
    mapValues(listenerRefs, ({ unsubscribe }) => unsubscribe())
    resetLocalStorage()
    return apiSignOut(auth)
  }
  
  const sendResetPasswordEmail = email =>
    httpsCallable(functions, 'createResetPasswordEmail')({
      email,
      origin: process.env.BASE_URL,
      _VERBOSE: process.env.NODE_ENV !== 'production',
    })
      .then(() => dispatch({ type: RESET_PASSWORD_EMAIL_SUCCESS }))
      .catch(payload => dispatch({ type: RESET_PASSWORD_EMAIL_ERROR, payload }))

  const uploadProfilePicture = (file, uid) =>
    new Promise(resolve => {
      const storageRef = ref(storage, `${PROFILE_STORAGE_REF}/${uid}-${(new Date()).getMilliseconds()}.${file.name.split('.').pop()}`)
      const uploadTask = uploadBytesResumable(storageRef, file)
      uploadTask.on('state_changed',
        snapshot => dispatch({ type: UPLOAD_PROGRESS, progress: 100 * snapshot.bytesTransferred / snapshot.totalBytes }),
        error => dispatch({ type: UPLOAD_ERROR, error }),
        () => {
          dispatch({ type: UPLOAD_COMPLETE })
          resolve()
        },
      )
    })
  
  /**
   * Resend an invite email to an already created user
   * @param {string} email
   */
  const resendInviteEmailAction = email =>
    addDoc(collection(db, EMAIL_COLLECTION), {
      _createdAtTime: serverTimestamp(),
      type: EMAIL_RE_INVITE,
      to: email,
      origin: window.location.origin,
    })
      .then(() => dispatch({ type: SUCCESS, payload: 'L\'invitation a bien été envoyée' }))
      .catch(err => {
        console.error(err)
        return dispatch({ type: ERROR, payload: 'L\'invitation n\'a pas été envoyée' })
      })
  
  const inviteUserAction = (user, notification) =>
    httpsCallable(functions, 'inviteUser')(user)
      .then(({ data }) => {
        if (notification)
          dispatch({
            type: SUCCESS,
            payload: `notifications.users.create.success`,
          })
        return data
      })
      .catch(err => {
        console.error(err)
        if (!notification) return // eslint-disable-line no-useless-return
        else if (err.message === 'No more licences') {
          dispatch({
            type: ERROR,
            payload: `notifications.users.create.licences`,
          })
        }
        else
          dispatch({
            type: ERROR,
            payload: `notifications.create.error`,
          })
      })
  
  return { listenProfile, getProfile, signIn, signOut, sendResetPasswordEmail, uploadProfilePicture, resendInviteEmailAction, inviteUserAction }
}

export default useAuth
