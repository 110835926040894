import React from 'react'
import { Button } from '@mui/material'
import { styled } from '@mui/styles'


const BackButton = styled(Button)(({ theme }) => ({
  color: theme.palette.grey[100],
  clipPath: `polygon(0 0, 0 100%, 80% 100%, 100% 50%, 80% 0)`,
  fontWeight: 800,
  borderRadius: 5,
  backgroundColor: theme.palette.tertiary.main,
  minWidth: 40,
  padding: 3,
  paddingRight: 8,
  '&:disabled': {
    backgroundColor: theme.palette.grey[200],
    color: 'white',
  },
  '&:hover': {
    backgroundColor: theme.palette.tertiary.dark,
  },
}))

const StyledCustomization = props => <BackButton variant='contained' {...props} />

export default StyledCustomization
