import React, { useEffect, useMemo, useState } from 'react'
import { FormControl, FormHelperText, IconButton, Input, InputAdornment, InputLabel, Link, TextField, Typography } from '@mui/material'
import CTAButton from '../_library/CTAButton'
import { REGEX_MAIL } from '../../_constants/regex'
import { useTranslation } from 'react-i18next'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { HOME } from '../../_constants/routes'
import { useNavigate } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'


const LoginForm = () => {

  const { t } = useTranslation()
  const navigate = useNavigate()
  const { signIn, sendResetPasswordEmail } = useAuth()

  const [email, setEmail] = useState(null)
  const [password, setPassword] = useState(null)
  const [showPassword, setShowPassword] = useState(false)
  const [isSubmitted, setSubmitted] = useState(false)
  const [errors, setErrors] = useState({})
  const [resetPasswordSubmitted, setResetPasswordSubmitted] = useState(false)

  useEffect(() => {
    if (email && email.length > 0 && !email.match(REGEX_MAIL)) setErrors(prev => ({ ...prev, email: t('auth.error.invalid-email') }))
    if (password && password.length > 0 && password.length < 6) setErrors(prev => ({ ...prev, password: t('auth.error.password-length') }))
    return () => setErrors({})
  }, [email, password, t])

  const enableSubmit = useMemo(() => email && password && Object.keys(errors).length === 0 && !isSubmitted, [email, password, errors, isSubmitted])

  const handleSubmit = e => {
    e.preventDefault()
    setSubmitted(true)
    signIn({ email, password })
      .then(() => navigate(HOME))
      .finally(() => setSubmitted(false))
  }

  const resetPassword = () => {
    if (!email) setErrors(prev => ({ ...prev, email: t('auth.error.invalid-email') }))
    else {
      setResetPasswordSubmitted(true)
      sendResetPasswordEmail(email)
        .finally(() => setResetPasswordSubmitted(false))
    }
  }

  return (
    <form onSubmit={handleSubmit} id='loginForm'>
      <TextField
        required
        onChange={e => setEmail(e.target.value)}
        name='email'
        type='email'
        value={email || ''}
        label='Email'
        fullWidth
        variant='standard'
        autoFocus
        disabled={isSubmitted}
        sx={{ my: 1 }}
        error={!!errors.email}
        helperText={errors.email}
      />
      <FormControl variant='standard' fullWidth error={!!errors.password} disabled={isSubmitted} required sx={{ my: 1 }}>
        <InputLabel htmlFor='password-field'>Password</InputLabel>
        <Input
          id='password-field'
          type={showPassword ? 'text' : 'password'}
          value={password || ''}
          onChange={e => setPassword(e.target.value)}
          endAdornment={
            <InputAdornment position='end' sx={{ ml: 0, mr: 1 }}>
              <IconButton
                aria-label='toggle password visibility'
                onClick={() => setShowPassword(prev => !prev)}
                onMouseDown={e => e.preventDefault()}
                edge='end'
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
        {errors.password && <FormHelperText id='password-helper-text' color='secondary'>{errors.password}</FormHelperText>}
      </FormControl>
      <CTAButton type='submit' fullWidth disabled={!enableSubmit} sx={{ my: 3 }}>Login</CTAButton>
      <Typography variant='body2' sx={{ textAlign: 'center' }}>
        Vous avez oublié votre mot de passe ?<br />
        <Link onClick={resetPassword} color='secondary' sx={{
          cursor: resetPasswordSubmitted ? 'initial' : 'pointer',
          ':hover': {
            textDecoration: resetPasswordSubmitted ? 'none' : 'underline',
          },
        }}>Réinitialiser par mail</Link>
      </Typography>
    </form>
  )
}

export default LoginForm
