import React, { useState } from 'react'
import { Box, Button, MenuItem, ClickAwayListener } from '@mui/material'
import { useTranslation } from 'react-i18next'
import photoIcon from '../../assets/avatar_team_member.svg'
import { makeStyles } from '@mui/styles'
import useAuth from '../../hooks/useAuth'


const useStyles = makeStyles(theme => ({
  root: {
    float: 'right',
    height: '100%',
    padding: theme.spacing(1),
    borderLeft: 'solid 1px',
    borderColor: theme.palette.grey[150],
    borderRadius: 0,
    '& span': {
      height: 64,
      width: 64,
      [theme.breakpoints.down('sm')]: {
        height: 40,
        width: 40,
      },
      borderRadius: 32,
      overflow: 'hidden',
      '& img': {
        objectFit: 'cover',
        height: '100%',
      },
    },
    [theme.breakpoints.down('xs')]: {
      boxSizing: 'none',
    },
  },
  menu: {
    top: 80,
    right: 0,
    width: 'fit-content',
    border: '1px solid',
    position: 'absolute',
    borderColor: theme.palette.grey[150],
    borderRadius: 0,
    backgroundColor: 'white',
  },
}))

export const Menu = () => {
  
  const classes = useStyles()
  const { t } = useTranslation()
  const profile = useAuth().getProfile()
  const { signOut } = useAuth()
  
  const [open, setOpen] = useState(false)
  
  return (
    <>
      <Button
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={() => setOpen(val => !val)}
        className={classes.root}
        id='menuBtn'
      >
        <Box component='img' src={profile.photoUrl || photoIcon} alt='avatar' sx={{ height: '100%' }} />
      </Button>
      {open && <ClickAwayListener onClickAway={() => setOpen(false)}>
        <div
          id='menu'
          className={classes.menu}
        >
          <MenuItem onClick={signOut} id='logoutBtn' sx={{
            fontSize: '1.2rem',
            fontWeight: 600,
            color: 'primary.dark',
            borderTop: '1px solid lightgrey',
            '&:first-of-type': {
              borderTop: 'none',
            },
            '&:hover': {
              color: 'secondary.main',
              backgroundColor: 'initial',
            },
          }}>{t('menu.logout')}</MenuItem>
        </div>
      </ClickAwayListener>}
    </>
  )
}

export default Menu
