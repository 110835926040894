import React from 'react'
import Page from '../_library/Page'
import NewCompanyForm from './NewCompanyForm'
import FormContainer from '../_library/FormContainer'
import InvitationDisplay from './InvitationDisplay'


const NewCompanyPage = () => (
  <Page title='Nouveau client'>
    <InvitationDisplay />
    <FormContainer form={<NewCompanyForm />} />
  </Page>
)

export default NewCompanyPage
