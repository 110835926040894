import React, { useEffect, useMemo, useState } from 'react'
import WriteCampfireForm from './WriteCampfireForm'
import DeleteButton from '../_library/DeleteButton'
import { Button, Stack, Typography } from '@mui/material'
import FormPage from '../_library/FormPage'
import useFirestore from '../../hooks/useFirestore'
import { CAMPFIRE_COLLECTION, CAMPFIRE_TEMPLATE_COLLECTION, COMPANY_COLLECTION, TEAM_COLLECTION, USER_COLLECTION } from '../../_constants/globals'
import { CAMPFIRES, EDIT_CAMPFIRE } from '../../_constants/routes'
import { useMatch } from 'react-router'
import { isNil, pickBy } from 'lodash'
import { Check, RocketLaunch } from '@mui/icons-material'
import { useStorage } from '../../hooks/useStorage'
import { useNavigate } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'


const WriteCampfirePage = () => {
  
  const match = useMatch(EDIT_CAMPFIRE)
  const campfireHooks = useFirestore(CAMPFIRE_COLLECTION)
  const dbCampfires = campfireHooks.getDocs()
  const companyHooks = useFirestore(COMPANY_COLLECTION)
  const teamHooks = useFirestore(TEAM_COLLECTION)
  const userHooks = useFirestore(USER_COLLECTION)
  const storageHook = useStorage('campfires')
  const navigate = useNavigate()
  const profile = useAuth().getProfile()
  const campfireTemplateHooks = useFirestore(CAMPFIRE_TEMPLATE_COLLECTION)
  
  const [data, setData] = useState({})
  
  const campfire = useMemo(() => dbCampfires.find(c => c.id === match?.params.id), [dbCampfires, match?.params.id])
  
  useEffect(() => {
    campfireHooks.listen({
      where: [['consultantUserRef', '==', userHooks.getDocRef(profile.id)]],
      orderBy: [['_createdAtTime', 'asc']],
    })
    return campfireHooks.unsubscribe
  }, [])
  
  const handleSubmit = async () => {
    const obj = pickBy({
      companyRef: companyHooks.getDocRef(data.client.id),
      teamRef: teamHooks.getDocRef(data.team.id),
      templateRef: campfireTemplateHooks.getDocRef(data.template.id),
      spreadsheetUrl: data.spreadsheet instanceof File ? await storageHook.uploadFileAction(data.spreadsheet) : null,
      debriefUrl: data.debrief instanceof File ? await storageHook.uploadFileAction(data.debrief) : null,
      comment: data.comment,
      consultantUserRef: userHooks.getDocRef(profile.id),
      consultantCompanyRef: profile.companyRef,
    }, val => !isNil(val))
    await campfire?.id ? campfireHooks.updateDoc(campfire.id, obj) : campfireHooks.addDoc(obj)
    return navigate(CAMPFIRES)
  }
  
  return (
    <FormPage
      title='Nouveau feu de camp en équipe'
      form={<WriteCampfireForm campfire={campfire} onChange={setData} />}
      actions={<Stack spacing={2}>
        <Button
          variant='contained'
          fullWidth
          color='tertiary'
          startIcon={<Check />}
          onClick={handleSubmit}
        >Enregistrer</Button>
        {campfire?.id && <DeleteButton deleteAction={() => campfireHooks.deleteDoc(campfire.id)} />}
        {campfire?.id && <Button
          variant='contained'
          fullWidth
          color='primary'
          startIcon={<RocketLaunch />}
          onClick={() => campfireHooks.updateDoc(campfire.id, { status: campfire?.status === 'actif' ? 'inactif' : 'actif' })}
        >{campfire?.status === 'actif' ? 'Désactiver' : 'Activer'}</Button>}
        <Typography variant='caption' color='grey.600'>status actuel: {campfire?.status || 'inactif'}</Typography>
      </Stack>}
    />
  )
}

export default WriteCampfirePage
